import React, { memo } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import { roundPercent } from 'views/Scenarios/utils/round';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils';
import { useSettings } from 'contexts';

type Props = {
  value: number;
  percentValue?: number;
  currency?: string | null;
  relative?: number;
  valueClassName?: string;
  relativeClassName?: string;
  showPercentsAsMainValue?: boolean;
  showPercentsTag?: boolean;
};

export const Price = memo<Props>(
  ({
    value,
    relative,
    currency,
    valueClassName,
    relativeClassName,
    showPercentsTag = true,
    showPercentsAsMainValue,
  }) => {
    const { t } = useTranslation();
    const { isFinancialsHidden } = useSettings();

    const percentageValue =
      relative === -Infinity || relative === Infinity ? t('notApplicable') : `${roundPercent(relative)} %`;

    return (
      <div>
        <span className={clsx(styles.value, valueClassName)}>
          {!showPercentsAsMainValue ? formatCurrency(value, currency, isFinancialsHidden) : percentageValue}
        </span>
        {!!relative && showPercentsTag && (
          <span
            className={clsx(
              styles.relative,
              {
                [styles.relativePositive]: relative > 0,
                [styles.relativeNegative]: relative < 0,
              },
              relativeClassName,
            )}
          >
            {percentageValue}
          </span>
        )}
      </div>
    );
  },
);

Price.displayName = 'Timeline.Price';
