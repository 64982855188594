import React, { FC } from 'react';
import { Route, getLinks } from 'react-router-hoc';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Redirect, Switch } from 'react-router-dom';

import { TabItem } from 'components';
import { ModuleName, ScreenName } from 'types';
import { ExpenseTypes } from './ExpenseTypes';
import { CompensationTypes } from './CompensationTypes';
import { ProjectRoles } from './ProjectRoles';
import { ProjectTags } from './Tags';

import styles from '../Settings.module.scss';
import { useTrackScreenView } from 'hooks';

export const links = getLinks({
  ExpenseTypes,
  CompensationTypes,
  ProjectRoles,
  ProjectTags,
});

const ProjectSettingsRoute = Route('/settings/project-settings');

const ProjectSettings: FC = () => {
  const { t } = useTranslation();
  useTrackScreenView(ModuleName.settings, ScreenName.projectSettings);

  return (
    <div className="flex max-h-100 align-start">
      <div className={clsx(styles.tabs, 'flex-column align-start mr-30')}>
        <TabItem exact={false} route={links.ExpenseTypes()} activeClassName={styles.active}>
          {t('settings.projectSettings.expenseTypes')}
        </TabItem>
        <TabItem exact={false} route={links.CompensationTypes()} activeClassName={styles.active}>
          {t('settings.projectSettings.compensationTypes')}
        </TabItem>
        <TabItem exact={false} route={links.ProjectRoles()} activeClassName={styles.active}>
          {t('settings.projectSettings.projectRoles')}
        </TabItem>
        <TabItem exact={false} route={links.ProjectTags()} activeClassName={styles.active}>
          {t('settings.projectSettings.tags')}
        </TabItem>
      </div>

      <div className="flex-1 max-h-100 flex flex-column overflow-auto">
        <Switch>
          <ExpenseTypes />
          <CompensationTypes />
          <ProjectRoles />
          <ProjectTags />
          <Redirect to={links.ExpenseTypes()} />
        </Switch>
      </div>
    </div>
  );
};

export default ProjectSettingsRoute(ProjectSettings);
