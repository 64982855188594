import React, { FC } from 'react';
import clsx from 'clsx';
import { Link, Tooltip } from 'components/common';
import { links } from 'App';

import styles from './styles.module.scss';

export interface ProjectInfoCellProps {
  id?: string;
  color: string;
  title: string;
  hasTooltip?: boolean;
  hasSubtitleTooltip?: boolean;
  className?: string;
  titleClassName?: string;
  subtitleClassName?: string;
  subtitle?: string;
  withProjectLink?: boolean;
  inCollapse?: boolean;
}

export const ProjectInfoCell: FC<ProjectInfoCellProps> = ({
  id,
  color,
  title,
  subtitle,
  className,
  titleClassName,
  subtitleClassName,
  hasTooltip,
  hasSubtitleTooltip,
  withProjectLink,
  inCollapse,
}) => {
  const projectName = (
    <Tooltip
      title={title}
      placement="top"
      hideTooltip={!hasTooltip}
      textClassName={clsx(styles.projectInfoCellTitle, titleClassName)}
    >
      {title}
    </Tooltip>
  );

  return (
    <div className={clsx(styles.projectInfoCell, className)}>
      <span
        className={clsx(styles.projectInfoCellColor, inCollapse && styles.projectInfoCellColorCollapse)}
        style={{ backgroundColor: color }}
      />

      {withProjectLink && id ? (
        <Link to={links.ProjectDetail({ id })} className={styles.link}>
          {projectName}
        </Link>
      ) : (
        projectName
      )}

      {subtitle && (
        <Tooltip
          title={subtitle}
          placement="top"
          hideTooltip={!hasSubtitleTooltip}
          textClassName={clsx(styles.projectInfoCellSubtitle, subtitleClassName)}
        >
          {subtitle}
        </Tooltip>
      )}
    </div>
  );
};
