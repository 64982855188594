import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, RadioGroup } from '@material-ui/core';
import { Popover, Radio, Tooltip } from 'components';
import { useIsOpen } from 'hooks';
import { DownTriangle } from 'icons';

import styles from './styles.module.scss';

type Option<D> = { id: D; name: string };

interface Props<T, D> {
  title: string;
  showPrefix?: boolean;
  value?: D;
  onChange: (value?: D) => void;
  options: T[];
  noSelectOptionStyle?: boolean;
  className?: string;
}

export const FilterRadioPopover = <D extends string, T extends Option<D>>({
  title,
  showPrefix,
  value,
  onChange,
  options,
  noSelectOptionStyle,
  className,
}: Props<T, D>) => {
  const [isOpen, onOpen, onClose] = useIsOpen();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<D | null>(value || null);

  const label = options?.find(({ id }) => id === value)?.name || '';

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setSelectedOption(value || null);
      }}
      buttonClassName={clsx('h-auto p-0', className)}
      button={
        value ? (
          <Button
            variant={noSelectOptionStyle ? 'text' : 'outlined'}
            color="secondary"
            className={noSelectOptionStyle ? styles.filterButton : styles.valueButton}
            onClick={onOpen}
          >
            <Tooltip title={label} textClassName={styles.tooltipTitle}>
              {showPrefix ? `${title}: ` : ''}
              {label}
            </Tooltip>
            <DownTriangle />
          </Button>
        ) : (
          <Button variant="text" color="secondary" className={styles.filterButton} onClick={onOpen}>
            {title}
            <DownTriangle />
          </Button>
        )
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <div className={styles.box}>
        <span className={styles.title}>{title}</span>
        <div className={clsx('form', styles.form)}>
          <div className={styles.formBox}>
            <RadioGroup
              name="radioGroup"
              aria-label="radio group"
              onChange={(_e, value) => setSelectedOption(value as D)}
              value={selectedOption}
            >
              {options?.map(({ id, name }) => (
                <Radio key={id} label={name} value={id} className={styles.radio} />
              ))}
            </RadioGroup>
          </div>

          <div className={styles.buttons}>
            <Button
              onClick={() => {
                onChange(selectedOption ?? undefined);
                onClose();
              }}
            >
              {t('common.filter.apply')}
            </Button>

            <Button
              color="secondary"
              variant="text"
              onClick={() => {
                setSelectedOption(null);
                onChange();
                onClose();
              }}
            >
              {t('common.filter.clear')}
            </Button>
          </div>
        </div>
      </div>
    </Popover>
  );
};
