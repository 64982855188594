import { TimelinePeriod, useTimelineContext } from '../contexts';
import { QUARTER_PERIOD, YEAR_PERIOD } from '../consts';
import { useMemo } from 'react';
import { getCurrentQuarter, getCurrentYear, getNextFourWeeks } from '../views/ResourcePlanning/utils';
import { isWeekend } from 'date-fns';

export const useTimelinePeriodDays = (date: Date): { days: Date[]; monthDays: Date[] } => {
  const { collapsedWeekends, hoursPeriod, timelinePeriod } = useTimelineContext();

  const yearDays = useMemo(() => getCurrentYear(date), [date]);

  const quarterDays = useMemo(() => getCurrentQuarter(date), [date]);

  const monthDays = useMemo(() => {
    return getNextFourWeeks(date).filter((item) => (collapsedWeekends ? !isWeekend(item) : item));
  }, [date, collapsedWeekends, hoursPeriod]);

  const getCurrDays = (timelinePeriod: TimelinePeriod) => {
    switch (timelinePeriod) {
      case QUARTER_PERIOD: {
        return quarterDays;
      }
      case YEAR_PERIOD: {
        return yearDays;
      }
      default: {
        return monthDays;
      }
    }
  };

  const days = useMemo(() => getCurrDays(timelinePeriod), [timelinePeriod, collapsedWeekends, date]);

  return { days, monthDays };
};
