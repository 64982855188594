export type Costs = {
  allocation?: number;
  allocationPercent?: number;
  actualAllocationPercent?: number;
  cost?: number;
  profit?: number;
  revenue?: number;
  margin?: number;
  internalRateAmount?: number;
  externalRateAmount?: number;
  leaves?: number;
  leavesPercent?: number;
  commission?: number;
};

export const roundValue = (value = 0) => {
  return Math.round(value * 100) / 100;
};

export const roundPercent = (value = 0) => {
  return value ? Math.round(value * 10) / 10 : 0;
};

export const roundCosts = <T extends Costs>(costs: T): T => ({
  ...costs,
  allocation: roundValue(costs.allocation),
  allocationPercent: roundValue(costs.allocationPercent),
  actualAllocationPercent: roundValue(costs.actualAllocationPercent),
  leaves: roundValue(costs.leaves),
  leavesPercent: roundValue(costs.leavesPercent),
  commission: roundValue(costs.commission),
  cost: roundValue(costs.cost),
  profit: roundValue(costs.profit),
  revenue: roundValue(costs.revenue),
  margin: roundValue(costs.margin),
  internalRateAmount: roundValue(costs.internalRateAmount),
  externalRateAmount: roundValue(costs.externalRateAmount),
});

export const parseCosts = (amount?: number | null) => (amount || 0) / 100;
