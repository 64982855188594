import { useExportReportLazyQuery, useGenerateXeroInvoiceLazyQuery } from 'generated/graphql';
import { downloadExcelXLS, graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { useAuth } from 'contexts';
import { ExportTarget } from 'generated/types';
import mixpanel from 'mixpanel-browser';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export const useExportBillingReport = () => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const [exportReport] = useExportReportLazyQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    fetchPolicy: 'no-cache',
  });

  const [generateXeroInvoice] = useGenerateXeroInvoiceLazyQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    fetchPolicy: 'no-cache',
  });

  const onExportReport = useCallback(async (projectId: string, reportId: string, reportTitle: string) => {
    const { data } = await exportReport({
      variables: {
        companyId: userData!.company.id,
        projectId,
        reportId,
      },
    });
    data && downloadExcelXLS(data.exportReport, reportTitle);
    mixpanel.track('Report exported', { Type: 'billing-report', From: 'list', Target: 'excel' });
  }, []);

  const onGenerateXeroInvoice = useCallback(async (projectId: string, reportId: string) => {
    toast.success(t('billing.export.xeroInvoiceMessage'));
    const { data } = await generateXeroInvoice({
      variables: {
        companyId: userData!.company.id,
        projectId,
        reportId,
      },
    });

    if (!data?.generateXeroInvoice) return;
    mixpanel.track('Report exported', { Type: 'billing-report', From: 'list', Target: 'xero' });
    window.open(data?.generateXeroInvoice, '_blank');
  }, []);

  const onOpenInGoogleReport = useCallback(async (projectId: string, reportId: string) => {
    toast.success(t('billing.export.googleSheetsMessage'));
    const { data } = await exportReport({
      variables: {
        companyId: userData!.company.id,
        projectId,
        reportId,
        exportType: ExportTarget.Google,
      },
    });
    mixpanel.track('Report exported', { Type: 'billing-report', From: 'list', Target: 'google-spreadsheets' });
    window.open(data?.exportReport, '_blank');
  }, []);

  return {
    onExportReport,
    onGenerateXeroInvoice,
    onOpenInGoogleReport,
  };
};
