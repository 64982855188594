import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { AbsoluteSpinner, AssignmentInfo, Autocomplete, DatePicker, LoadingButton, NumberTextField } from 'components';
import { Assignment, CompensationDataFragment, Currency, Scalars } from 'generated/types';
import { Button, InputLabel, TextField } from '@material-ui/core';
import { graphqlOnError, submitForm, valueToCurrency } from 'utils';
import { useCompensationTypesQuery, useCurrenciesQuery } from 'generated/graphql';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';

import styles from './styles.module.scss';

export interface CompensationFormValues {
  amount: number | null;
  assignment: Pick<Assignment, 'id'> | null;
  currency: Currency;
  date: string | null;
  notes?: string;
  type: { id: string; name: string } | null;
}

interface NewCostRateProps {
  onSubmit: (values: CompensationFormValues) => void | Promise<void>;
  onCancel: () => void;
  submitLabel?: string;
  compensations?: CompensationDataFragment[];
  assignments?: Pick<Assignment, 'id'>[];
}

export const NewCompensation = ({ submitLabel, onSubmit, onCancel, compensations, assignments }: NewCostRateProps) => {
  const { t } = useTranslation();
  const { compensationId } = useParams<{ compensationId: string }>();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const editingCompensation = compensations?.find(({ id }) => id === compensationId);

  const { data: { compensationTypes = [] } = {}, loading: compensationTypeLoading } = useCompensationTypesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: { companyId: userData!.company.id },
  });

  const { data: { currencies = [] as Currency[] } = {}, loading: currenciesLoading } = useCurrenciesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: { companyId: userData!.company.id },
  });

  const getCurrencyLabel = (currency: Currency) =>
    `${currency?.code?.toUpperCase()} - ${t(`currency.${currency?.code}`)}`;

  const findCurrency = (id?: string | null) => currencies.find((currency) => currency.id === id);

  const initialCurrency = useMemo(
    () =>
      (editingCompensation?.currencyId
        ? findCurrency(editingCompensation?.currencyId)
        : findCurrency(userData?.company.primaryCurrencyId))!,
    [currencies],
  );

  const initialValues = useMemo(
    () => ({
      type: editingCompensation?.type ? { id: editingCompensation.type, name: editingCompensation.typeName } : null,
      amount: editingCompensation ? valueToCurrency(editingCompensation.amount) : null,
      currency: initialCurrency,
      date: editingCompensation?.date ?? null,
      notes: editingCompensation?.notes ?? '',
      assignment:
        (editingCompensation?.assignmentId && assignments?.find(({ id }) => id === editingCompensation.assignmentId)) ||
        null,
    }),
    [editingCompensation, assignments],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        type: Yup.object().nullable().required(t('validation.required')),
        assignment: Yup.object().nullable(),
        amount: Yup.number()
          .typeError(t('validation.validNumber'))
          .required(t('validation.required'))
          .test('maxDigitsAfterDecimal', t('validation.decimal'), (number) =>
            /^\d+(\.\d{1,2})?$/.test(number?.toString() as string),
          )
          .positive(t('validation.positive')),
        currency: Yup.object().required(t('validation.required')),
        date: Yup.string().nullable().required(t('validation.required')),
        notes: Yup.string().nullable(),
      }),
    [],
  );

  const {
    isSubmitting,
    submitCount,
    handleSubmit,
    setValues,
    setFieldValue,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik<CompensationFormValues>({
    initialValues,
    validationSchema,
    onSubmit: (values: CompensationFormValues, { setSubmitting }: FormikHelpers<CompensationFormValues>) => {
      submitForm(values, setSubmitting, onSubmit);
    },
  });

  useEffect(() => {
    if (initialValues.type) {
      setValues(initialValues);
    }
  }, [initialValues]);

  useEffect(() => {
    if (!values.currency && initialCurrency) {
      setFieldValue('currency', initialCurrency);
    }
  }, [initialCurrency, currencies]);

  const renderAssignmentsOption = ({ project, seniority, role }: Assignment) => {
    return <AssignmentInfo data={{ ...project, color: project.color || '', seniority: seniority || '', role }} />;
  };

  if (currenciesLoading || compensationTypeLoading || (!compensations && compensationId)) return <AbsoluteSpinner />;

  return (
    <form className="form">
      <div>
        <InputLabel required>{t('forms.newCompensation.compensationType')}</InputLabel>
        <Autocomplete
          className="mb-24"
          name="type"
          value={values.type}
          onBlur={handleBlur}
          placeholder={t('forms.newCompensation.compensationType')}
          options={compensationTypes}
          error={touched.type ? errors.type : undefined}
          onChange={(type: CompensationFormValues['type']) => {
            setValues({ ...values, type });
          }}
        />

        <div className={styles.twoItemsBox}>
          <div className="flex-1">
            <InputLabel required>{t('forms.newCompensation.amount')}</InputLabel>
            <NumberTextField
              error={Boolean((submitCount || touched.amount) && errors.amount)}
              helperText={(submitCount || touched.amount) && t(errors.amount!)}
              onBlur={handleBlur}
              onChange={handleChange}
              name="amount"
              value={values.amount}
              placeholder="0"
              className="mb-24"
            />
          </div>

          <div className="flex-1">
            <InputLabel required>{t('forms.newCompensation.currency')}</InputLabel>
            {values.currency ? (
              <Autocomplete
                placeholder={t('forms.newCompensation.currency')}
                className="mb-24"
                value={values.currency}
                name="currency"
                onBlur={handleBlur}
                disableClearable
                error={touched.currency ? errors.currency : undefined}
                getOptionLabel={getCurrencyLabel}
                options={currencies}
                onChange={(currency: CompensationFormValues['currency']) => setValues({ ...values, currency })}
              />
            ) : (
              ''
            )}
          </div>
        </div>

        <InputLabel>{t('forms.newCompensation.assignment')}</InputLabel>
        <Autocomplete
          className="mb-24"
          name="assignment"
          value={values.assignment}
          onBlur={handleBlur}
          placeholder={t('forms.newCompensation.assignment')}
          options={assignments}
          error={touched.assignment ? errors.assignment : undefined}
          onChange={(assignment: Assignment) => setValues({ ...values, assignment })}
          getOptionLabel={(option: Assignment) => option.project.name}
          renderOption={renderAssignmentsOption}
        />

        <InputLabel required>{t('forms.newCompensation.date')}</InputLabel>
        <DatePicker
          value={values.date}
          error={Boolean(submitCount && errors.date)}
          helperText={!!submitCount && errors.date}
          onChange={(date: Scalars['DateTime']) => setValues({ ...values, date })}
        />

        <InputLabel className="mt-24">{t('forms.newCompensation.notes')}</InputLabel>
        <TextField
          name="notes"
          size="medium"
          multiline
          rows={4}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.notes}
          placeholder={t('forms.typeNoteHere')}
          className={styles.note}
        />
      </div>
      <div className="controls">
        <LoadingButton onClick={() => handleSubmit()} loading={isSubmitting} className="mr-8">
          {submitLabel ?? t('forms.create')}
        </LoadingButton>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          {t('forms.cancel')}
        </Button>
      </div>
    </form>
  );
};
