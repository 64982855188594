import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { CellProps, Column, FooterProps, useBlockLayout, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import clsx from 'clsx';

import styles from './styles.module.scss';
import {
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  format,
  isWeekend,
  isWithinInterval,
  parse,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { DEFAULT_DATE_FORMAT, DEFAULT_MONTH_FORMAT } from 'consts';
import {
  LeaveTimeLogDataFragment,
  MemberProjectDataFragment,
  ProjectAssignmentTimeLogDataFragment,
  TotalTimeLog,
} from 'generated/types';
import { useTranslation } from 'react-i18next';
import { PeriodTimeLogFormValues, TimeLogCell, TimeLogFormValues } from './TimeLogCell';
import { AbsoluteSpinner, EmptyState } from 'components';
import { TableDayHeader } from './TableDayHeader';
import { getAssignmentMonthPeriod } from './helpers';
import { useDeviceTypeByWidth } from 'hooks';

export type LogData = Pick<TotalTimeLog, 'date' | 'totalMinutes' | 'member_id'>;
type Assignment = Pick<ProjectAssignmentTimeLogDataFragment, 'id' | 'startDate' | 'endDate' | 'projectId'> & {
  member?: ProjectAssignmentTimeLogDataFragment['member'];
};

export type Data = {
  logs: LogData[];
  leaves: LeaveTimeLogDataFragment[];
  assignment: Assignment;
  overtimeMultiplier?: number | null;
};

type GroupedData = {
  [x: string]: LogData;
};

type TableData = { logs: GroupedData; total: number; leaves: LeaveTimeLogDataFragment[]; assignment: Assignment };

export type CellData<D extends Data = Data> = Omit<D, 'logs'> & {
  log: LogData | undefined;
  day: Date;
  assignment: Assignment;
};

interface Props<D extends Data = Data> {
  nameColumn: Column<Omit<D, 'logs'> & TableData>;
  data: D[];
  month: string;
  loading?: boolean;
  onCellSubmit: (cell: CellData<D>, formValues: TimeLogFormValues | PeriodTimeLogFormValues) => Promise<void>;
  tableClassName?: string;
  editable: boolean;
}

export const TimeTrackingTable = <D extends Data = Data>({
  nameColumn,
  data,
  month,
  loading,
  onCellSubmit,
  tableClassName,
  editable,
}: Props<D>): ReactElement<Props<D>> => {
  const { t } = useTranslation();
  const [isWeekendVisible, setIsWeekendVisible] = useState(true);
  const { isMobileDevice } = useDeviceTypeByWidth();

  const monthDays = useMemo(() => {
    const monthDate = parse(month, DEFAULT_MONTH_FORMAT, new Date());
    return eachDayOfInterval({ start: startOfMonth(monthDate), end: endOfMonth(monthDate) });
  }, [month]);

  const tableData = useMemo<Array<Omit<D, 'logs'> & TableData>>(
    () =>
      data.map(({ logs, ...item }) => ({
        ...item,
        logs: logs.reduce<GroupedData>((logsData, log) => ({ ...logsData, [log.date]: log }), {}),
        total: logs.reduce((total, { totalMinutes }) => total + totalMinutes, 0),
      })),
    [data],
  );

  const columns = useMemo<Array<Column<TableData>>>(
    () => [
      {
        ...(nameColumn as Column<TableData>),
        Footer() {
          return <span className={styles.total}>{t('table.total')}</span>;
        },
        sticky: 'left',
        width: isMobileDevice ? 120 : 156,
        maxWidth: isMobileDevice ? 120 : 156,
      },
      {
        Header: <span className={styles.headerLabel}>{t('timeTracking.logged')}</span>,
        accessor: 'total',
        Cell({ row }) {
          const day = parse(month, DEFAULT_MONTH_FORMAT, new Date());

          return (
            <div className={styles.cell}>
              <TimeLogCell
                minutes={row.values.total}
                editable
                monthMode
                day={day}
                onSubmit={(formValues) =>
                  onCellSubmit(({ day, ...row.original } as unknown) as CellData<D>, {
                    ...formValues,
                    ...getAssignmentMonthPeriod({
                      monthDay: day,
                      assignmentStartDate: new Date(row.original.assignment.startDate),
                      assignmentEndDate: new Date(row.original.assignment.endDate),
                    }),
                  })
                }
              />
            </div>
          );
        },
        Footer({ rows }: FooterProps<TableData>) {
          const total = rows.reduce((sum, row) => (row.values.total || 0) + sum, 0);

          return <TimeLogCell minutes={total} />;
        },
        sticky: 'left',
        left: 186,
        width: isMobileDevice ? 70 : 84,
        maxWidth: isMobileDevice ? 70 : 84,
      },
      ...monthDays.map((day, index) => ({
        Header: (
          <TableDayHeader
            day={day}
            setIsWeekendVisible={setIsWeekendVisible}
            isWeekendVisible={isWeekendVisible}
            isFirstDay={!index}
            isLastDay={monthDays.length - 1 === index}
          />
        ),
        id: day.toISOString(),
        accessor: ({ logs, ...props }: TableData) => ({
          ...props,
          log: logs[format(day, DEFAULT_DATE_FORMAT)],
        }),
        Cell({
          value,
        }: CellProps<
          TableData,
          Omit<D, 'logs'> & {
            log: LogData | undefined;
            leaves: LeaveTimeLogDataFragment[];
            project?: MemberProjectDataFragment;
          }
        >) {
          const { assignment, project, overtimeMultiplier, leaves, log } = value;
          const isInRange = isWithinInterval(day, {
            start: assignment.startDate ? startOfDay(new Date(assignment.startDate)) : new Date(),
            end: assignment.endDate ? endOfDay(new Date(assignment.endDate)) : new Date(),
          });

          return (
            <div
              className={clsx(
                styles.cell,
                index === monthDays.length - 1 && styles.lastCalendarCell,
                !isInRange && styles.withoutAssignment,
                isWeekend(day) && styles.weekend,
              )}
            >
              <TimeLogCell
                assignmentId={assignment.id}
                member={assignment.member}
                project={project}
                minutes={log?.totalMinutes || 0}
                leaves={leaves}
                overtimeMultiplier={overtimeMultiplier}
                editable={editable && isInRange}
                day={day}
                onSubmit={(formValues) => onCellSubmit({ ...value, day }, { ...formValues })}
              />
            </div>
          );
        },
        Footer({ rows }: FooterProps<TableData>) {
          const total = rows.reduce(
            (sum, row) => (row.original.logs[format(day, DEFAULT_DATE_FORMAT)]?.totalMinutes || 0) + sum,
            0,
          );

          return <TimeLogCell minutes={total} />;
        },
        width: 46,
      })),
    ],
    [monthDays, isWeekendVisible],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    footerGroups,
    prepareRow,
    setHiddenColumns,
    allColumns,
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useBlockLayout,
    useSticky,
  );

  useEffect(() => {
    if (isWeekendVisible) {
      setHiddenColumns([]);

      return;
    }

    const weekendsIds = allColumns.reduce((acc: string[], column) => {
      if (isWeekend(new Date(column.id))) {
        acc.push(column.id);
      }

      return acc;
    }, []);

    setHiddenColumns(weekendsIds);
  }, [isWeekendVisible, allColumns]);

  if (loading) {
    return (
      <div className={styles.spinnerContainer}>
        <AbsoluteSpinner />
      </div>
    );
  }

  return (
    <div {...getTableProps()} className={clsx(styles.table, tableClassName)}>
      <div className={styles.header}>
        {headerGroups.map((headerGroup) => {
          const props = headerGroup.getHeaderGroupProps();

          return (
            // eslint-disable-next-line react/jsx-key
            <div {...props} style={{ minWidth: props.style?.width }} className={styles.row}>
              {headerGroup.headers.map((column, index) => {
                const props = column.getHeaderProps();
                const isDayCell = !('data-sticky-td' in props && props['data-sticky-td']);

                return (
                  // eslint-disable-next-line react/jsx-key
                  <div {...props} className={clsx(isDayCell && styles.dayCell)}>
                    <div
                      className={clsx(
                        styles.cell,
                        index === 0 && styles.name,
                        isWeekend(new Date(column.id)) && styles.weekend,
                        index === headerGroup.headers.length - 2 && styles.lastCalendarCell,
                      )}
                    >
                      {column.render('Header')}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {tableData.length ? (
        <>
          <div {...getTableBodyProps()} className={styles.body}>
            {rows.map((row, index) => {
              prepareRow(row);
              const props = row.getRowProps();

              return (
                // eslint-disable-next-line react/jsx-key
                <div
                  {...props}
                  style={{ minWidth: props.style?.width }}
                  className={clsx(styles.row, index % 2 !== 0 ? styles.odd : styles.even)}
                >
                  {row.cells.map((cell) => {
                    const props = cell.getCellProps();
                    const isDayCell = !('data-sticky-td' in props && props['data-sticky-td']);

                    return (
                      // eslint-disable-next-line react/jsx-key
                      <div {...props} className={clsx(isDayCell && styles.dayCell)}>
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className={styles.footer}>
            {footerGroups.map((footerGroup) => {
              const props = footerGroup.getHeaderGroupProps();

              return (
                // eslint-disable-next-line react/jsx-key
                <div {...props} style={{ minWidth: props.style?.width }} className={styles.row}>
                  {footerGroup.headers.map((column, index) => {
                    const props = column.getHeaderProps();
                    const isDayCell = !('data-sticky-td' in props && props['data-sticky-td']);

                    return (
                      // eslint-disable-next-line react/jsx-key
                      <div {...props} className={clsx(isDayCell && styles.dayCell)}>
                        <div className={clsx(styles.cell, index === 0 && styles.footerName)}>
                          {column.render('Footer')}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <EmptyState className={styles.empty} />
      )}
    </div>
  );
};
