import React, { useCallback, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { debounce } from '@material-ui/core';
import { links } from 'App';
import { Search } from 'components';
import { useQueryParams } from 'utils/useQueryParams';
import { Filters } from 'views/ResourcePlanning/types';

export const SearchField = () => {
  const { search, availability, ...query } = useQueryParams<Filters>();
  const { params } = useRouteMatch<{ type?: 'project' }>();
  const { push } = useHistory();
  const [searchQuery, setSearchQuery] = useState(search ?? '');

  window.addEventListener('keydown', function (e) {
    if (e.keyCode === 114 || ((e.ctrlKey || e.metaKey) && e.keyCode === 70)) {
      const searchElement = document.getElementById('resizable-search');

      if (searchElement && searchElement !== document.activeElement) {
        e.preventDefault();
        searchElement?.focus();
      } else {
        return true;
      }
    }
  });

  const setQueryParams = useCallback(
    debounce((queryParams: Filters) => {
      return push(links.ResourcePlanning({ availability, ...params, ...query, ...queryParams }));
    }, 200),
    [push, params, availability, query],
  );

  const onSearch = useCallback(
    (value) => {
      setSearchQuery(value);
      setQueryParams({ search: value });
    },
    [setQueryParams],
  );

  return <Search onChange={onSearch} value={searchQuery} />;
};
