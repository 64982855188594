import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../contexts';
import { ModuleName, ScreenName } from 'types';

interface Settings {
  loading?: boolean;
  isNoAuthorizedUser?: boolean;
  additionalProperties?: { [key: string]: string };
  deps?: string[];
}

export const useTrackScreenView = (module: ModuleName, screenName: ScreenName, settings?: Settings): void => {
  const { userData } = useAuth();
  const { loading, isNoAuthorizedUser, additionalProperties, deps = [] } = settings || {};

  if (!userData && !isNoAuthorizedUser) return;

  useEffect(() => {
    if (loading) return;

    mixpanel.track('Screen viewed', {
      Module: module,
      'Screen name': screenName,
      ...additionalProperties,
    });
  }, [loading, ...(deps || [])]);
};
