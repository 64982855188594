import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { Autocomplete, CountChip, Popover, Tag, Tooltip } from 'components';
import { useIsOpen } from 'hooks';
import { DownTriangle } from 'icons';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete';

import styles from './styles.module.scss';

type Option = { id: string; name?: string; first_name?: string | null; last_name?: string | null };

interface Props<T> {
  title: string;
  value?: string[];
  placeholder?: string;
  onChange: (value?: string[]) => void;
  options: T[];
  renderTag?: (tag: T, onDelete?: () => void) => JSX.Element;
  getOptionLabel?: (item: T) => string;
  getLabelPrefix?: (item: T) => JSX.Element;
}

export const FilterTagsPopover = <T extends Option>({
  title,
  placeholder,
  value,
  onChange,
  options,
  renderTag,
  getOptionLabel,
  getLabelPrefix,
}: Props<T>) => {
  const [isOpen, onOpen, onClose] = useIsOpen();
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<T[]>([]);

  const selectedOption = options?.find(({ id }) => id === value?.[0]);
  const label = getOptionLabel && selectedOption ? getOptionLabel?.(selectedOption) : selectedOption?.name;
  const selectedAdditionalOptionsCount =
    value && value.length > 1 ? <CountChip count={value.length - 1} className="ml-4" /> : null;

  useEffect(() => {
    if (!options.length || !value) return;

    setSelectedOptions(options.filter((option) => value?.includes(option.id)));
  }, [value, options]);

  const filterOptions = (options: T[], { inputValue }: FilterOptionsState<T>): T[] => {
    return [...options].filter((option) => {
      if ('name' in option) {
        return option.name?.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase());
      }

      if ('first_name' in option && 'last_name' in option) {
        return `${option.first_name} ${option.last_name}`.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase());
      }

      return true;
    });
  };

  const onDelete = (id: string) => setSelectedOptions((prev) => prev.filter((option) => option.id !== id));

  const defaultRenderTag = ({ id, name }: T) => (
    <Tag key={id} name={name || ''} className={styles.tag} color="" onDelete={() => onDelete(id)} />
  );

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setSelectedOptions(options.filter((option) => value?.includes(option.id)));
      }}
      buttonClassName="h-auto p-0"
      button={
        value?.length ? (
          <Button variant="outlined" color="secondary" className={styles.valueButton} onClick={onOpen}>
            {selectedOption ? getLabelPrefix?.(selectedOption) : ''}
            <Tooltip title={label || ''} textClassName={styles.tooltipTitle}>
              {label}
            </Tooltip>
            {selectedAdditionalOptionsCount}
            <DownTriangle />
          </Button>
        ) : (
          <Button variant="text" color="secondary" className={styles.filterButton} onClick={onOpen}>
            {title}
            <DownTriangle />
          </Button>
        )
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <div className={styles.box}>
        <span className={styles.title}>{title}</span>
        <div className={clsx('form', styles.form)}>
          <div className={styles.formBox}>
            <Autocomplete
              placeholder={placeholder || title}
              name="tags"
              value={[]}
              options={options}
              filterOptions={(options: T[], params: FilterOptionsState<T>) => {
                const filtered = filterOptions(options, params);

                const selectedOptionsIds = selectedOptions.map(({ id }) => id);
                return filtered.filter((item: T) => !selectedOptionsIds.includes(item.id));
              }}
              getOptionLabel={getOptionLabel}
              onChange={(value: T[]) => setSelectedOptions((prev) => [...prev, ...value])}
              classes={{ inputRoot: styles.autocomplete }}
              multiple
              disableClearable
            />

            <div className="flex gap-4 flex-wrap mt-8">
              {selectedOptions.map(renderTag ? (item) => renderTag(item, () => onDelete(item.id)) : defaultRenderTag)}
            </div>
          </div>

          <div className={styles.buttons}>
            <Button
              onClick={() => {
                onChange(selectedOptions.map(({ id }) => id));
                onClose();
              }}
            >
              {t('common.filter.apply')}
            </Button>

            <Button
              color="secondary"
              variant="text"
              onClick={() => {
                setSelectedOptions([]);
                onChange();
                onClose();
              }}
            >
              {t('common.filter.clear')}
            </Button>
          </div>
        </div>
      </div>
    </Popover>
  );
};
