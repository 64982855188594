import React, { FC } from 'react';
import { CommonFilterData, CommonFiltersPopover, HideMembersDrawer, Popover } from 'components';
import { Close, EyeClosed, FilterIcon } from 'icons';
import { useIsOpen } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { CommonFilterOptions } from 'types';

import styles from './styles.module.scss';

export type ChangeFiltersData = Partial<{
  employmentType: string[];
  seniority: string[];
  specialization: string[];
  excludeMembers: string[];
  reportingTo: string[];
  client: string[];
  pm: string[];
  type: string[];
  skill: string[];
}>;

interface Props {
  onChange: (data: ChangeFiltersData) => void;
  filtersOptions: CommonFilterOptions[];
  filterButtonClassName?: string;
  showHideMemberFilter?: boolean;
}

export const CommonFilters: FC<Props & ChangeFiltersData> = ({
  onChange,
  filtersOptions,
  filterButtonClassName,
  showHideMemberFilter,
  employmentType = [],
  seniority = [],
  specialization = [],
  excludeMembers = [],
  reportingTo = [],
  client = [],
  pm = [],
  type = [],
}) => {
  const { t } = useTranslation();
  const [isOpenFilters, onOpenFilters, onCloseFilters] = useIsOpen(false);
  const [isOpenHideMembersDrawer, onOpenHideMembersDrawer, onCloseHideMembersDrawer] = useIsOpen(false);
  const filterCount =
    (employmentType?.length || 0) +
    (seniority?.length || 0) +
    (specialization?.length || 0) +
    (reportingTo?.length || 0) +
    (client?.length || 0) +
    (pm?.length || 0) +
    (type?.length || 0);
  const excludeMembersCount = excludeMembers?.length || 0;

  const onSubmitFilters = (data: CommonFilterData) => {
    onChange(data);
    onCloseFilters();
  };

  const onSubmitHideMembers = (data: string[]) => {
    onChange({ excludeMembers: data });
    onCloseHideMembersDrawer();
  };

  const clearFilters = () => {
    onChange({
      employmentType: undefined,
      excludeMembers: undefined,
      seniority: undefined,
      specialization: undefined,
      reportingTo: undefined,
      client: undefined,
      pm: undefined,
      type: undefined,
    });
  };

  return (
    <>
      <div className={styles.buttonContainer}>
        <Popover
          isOpen={isOpenFilters}
          onClose={onCloseFilters}
          button={
            <Button
              variant="text"
              color="secondary"
              className={clsx(styles.filterButton, filterButtonClassName)}
              startIcon={<FilterIcon className={styles.buttonIcon} />}
              onClick={onOpenFilters}
            >
              {t('common.filter.title')}
              {filterCount ? <div className={styles.filterCount}>{filterCount}</div> : ''}
            </Button>
          }
        >
          <div className={styles.box}>
            <CommonFiltersPopover
              open={isOpenFilters}
              onSubmit={onSubmitFilters}
              onClearFilters={() => {
                onCloseFilters();
                clearFilters();
              }}
              filtersOptions={filtersOptions}
              employmentType={employmentType}
              seniority={seniority}
              specialization={specialization}
              reportingTo={reportingTo}
              client={client}
              pm={pm}
              type={type}
            />
          </div>
        </Popover>

        {showHideMemberFilter ? (
          <Button
            variant="text"
            color="secondary"
            className={styles.filterButton}
            startIcon={<EyeClosed className={styles.buttonIcon} />}
            onClick={onOpenHideMembersDrawer}
          >
            {t('common.filter.hideMembers')}
            {excludeMembersCount ? <div className={styles.filterCount}>{excludeMembersCount}</div> : ''}
          </Button>
        ) : (
          ''
        )}
        {filterCount || excludeMembersCount ? (
          <Button
            variant="text"
            color="secondary"
            startIcon={<Close className={styles.buttonIcon} />}
            onClick={clearFilters}
          >
            {t('common.filter.clearAll')}
          </Button>
        ) : undefined}
      </div>

      {showHideMemberFilter ? (
        <HideMembersDrawer
          open={isOpenHideMembersDrawer}
          onClose={onCloseHideMembersDrawer}
          onSubmit={onSubmitHideMembers}
          excludeMembers={excludeMembers}
        />
      ) : (
        ''
      )}
    </>
  );
};
