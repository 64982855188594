import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';

import mixpanel from 'mixpanel-browser';
import { CommonFilterOptions, ModuleName } from 'types';
import { links } from 'App';
import { CommonFiltersSection, FilterRadioPopover, Switch } from 'components';
import { useQueryParams } from 'utils/useQueryParams';
import { localStorageManager } from 'services';
import { GROUP_MEMBERS_BY, GROUP_PROJECTS_BY } from 'consts';
import { EmploymentType } from 'generated/types';
import { Filters, ProjectsGroupingTypesFilter, TeamGroupingTypesFilter } from 'views/ResourcePlanning/types';
import { useResourcePlanningFilters } from 'views/ResourcePlanning/utils';

import clsx from 'clsx';
import styles from './styles.module.scss';

export const TimelineFilters = memo(() => {
  const { groupingType, showRequests } = useQueryParams<Filters>();
  const { filters, onChangeFilters } = useResourcePlanningFilters();
  const query = useQueryParams();
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ type?: 'project' }>();
  const { push } = useHistory();
  const [isShowRequest, setIsShowRequest] = useState(showRequests ?? true);

  const teamGroupingOptions = useMemo(
    () =>
      Object.values(TeamGroupingTypesFilter).map((filter) => ({
        id: filter,
        name: t(`resourcePlanning.timeline.filters.groupBy.${filter}`),
      })),
    [],
  );

  const projectsGroupingOptions = useMemo(
    () =>
      Object.values(ProjectsGroupingTypesFilter).map((filter) => ({
        id: filter,
        name: t(`resourcePlanning.timeline.filters.groupBy.${filter}`),
      })),
    [],
  );

  return (
    <>
      <div className={styles.filters}>
        <div id="hide-team-member-menu" />
        {params.type !== 'project' ? (
          <>
            <FilterRadioPopover
              title={t('resourcePlanning.groupBy')}
              className="mr-8"
              showPrefix
              value={
                groupingType || localStorageManager.getItem(GROUP_MEMBERS_BY) || TeamGroupingTypesFilter.availability
              }
              noSelectOptionStyle
              onChange={(groupingType) => {
                mixpanel.track('Grouping updated', {
                  'Screen name': ModuleName.resourcePlanning,
                  Grouping: groupingType,
                });
                localStorageManager.setItem(GROUP_MEMBERS_BY, groupingType);
                push(
                  links.ResourcePlanning({
                    ...params,
                    ...filters,
                    ...query,
                    groupingType: groupingType as TeamGroupingTypesFilter,
                  }),
                );
              }}
              options={teamGroupingOptions}
            />

            <CommonFiltersSection
              onChange={(value) => {
                onChangeFilters({
                  ...('employmentType' in value && { membersTypes: value.employmentType as EmploymentType[] }),
                  ...('specialization' in value && { membersSpecialization: value.specialization }),
                  ...('seniority' in value && { membersSeniority: value.seniority }),
                  ...('skill' in value && { membersSkills: value.skill }),
                });
              }}
              filtersOptions={[
                CommonFilterOptions.employmentType,
                CommonFilterOptions.seniority,
                CommonFilterOptions.specialization,
                CommonFilterOptions.skill,
              ]}
              employmentType={filters.membersTypes}
              seniority={filters.membersSeniority}
              specialization={filters.membersSpecialization}
              skill={filters.membersSkills}
            />
          </>
        ) : (
          <FilterRadioPopover
            title={t('resourcePlanning.groupBy')}
            showPrefix
            value={
              groupingType ?? localStorageManager.getItem(GROUP_PROJECTS_BY) ?? ProjectsGroupingTypesFilter.projectType
            }
            noSelectOptionStyle
            onChange={(groupingType) => {
              mixpanel.track('Grouping updated', {
                'Screen name': ModuleName.resourcePlanning,
                Grouping: groupingType,
              });
              localStorageManager.setItem(GROUP_PROJECTS_BY, groupingType);
              push(
                links.ResourcePlanning({
                  ...params,
                  ...filters,
                  ...query,
                  groupingType: groupingType as ProjectsGroupingTypesFilter,
                }),
              );
            }}
            options={projectsGroupingOptions}
          />
        )}
      </div>

      <div className={clsx(styles.filters, 'justify-content-end')}>
        {params.type !== 'project' && (
          <>
            <span className={styles.hideRequest}>{t('resourcePlanning.timeline.filters.hideRequests')}</span>
            <Switch
              className="mr-4"
              checked={!isShowRequest}
              onChange={() => {
                setIsShowRequest(!isShowRequest);
                push(links.ResourcePlanning({ ...params, ...filters, ...query, showRequests: !isShowRequest }));
              }}
              color="primary"
              name="showRequests"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </>
        )}
      </div>
    </>
  );
});

TimelineFilters.displayName = 'Timeline.Filters';
