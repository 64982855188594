import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { EmploymentType, Maybe, Member } from 'generated/types';
import { useTranslation } from 'react-i18next';
import { ChevronIcon, EyeClosed, ProjectsLink } from 'icons';
import { Avatar, Link, SIZES, Tooltip } from 'components/common';
import React, { memo, PropsWithChildren, ReactNode } from 'react';
import { getAcronym } from 'utils';

import styles from './styles.module.scss';
import { DeleteConfirmOptions, Menu } from './Menu';
import { links } from 'App';

type MemberData = Pick<Member, 'first_name' | 'last_name' | 'job_title' | 'color'> & {
  id?: string;
  employment_type?: EmploymentType;
  specialization?: string | null;
};

type Props = {
  onExpand?: (id: string | null) => void;
  onDelete?: () => void;
  onHide?: () => void;
  deleteConfirmOptions?: DeleteConfirmOptions;
  expanded?: boolean;
  className?: string;
  tileClassName?: string;
  subtitleClassName?: string;
  headingClassName?: string;
  headingTitleClassName?: string;
  floatOptionsClassName?: string;
  data: MemberData;
  projectCount?: number;
  showAvatar?: boolean;
  disableNavigation?: boolean;
  hasTooltip?: boolean;
  additionalExpandComponent?: ReactNode;
  longTrim?: boolean;
  seniority?: Maybe<string>;
  endAdornment?: ReactNode;
};

export const MemberTile = memo<PropsWithChildren<Props>>(
  ({
    data: { first_name, last_name, job_title, id, employment_type, color, specialization },
    projectCount,
    className,
    tileClassName,
    subtitleClassName,
    headingClassName,
    headingTitleClassName,
    floatOptionsClassName,
    onExpand,
    onDelete,
    onHide,
    deleteConfirmOptions,
    expanded,
    showAvatar,
    disableNavigation,
    additionalExpandComponent,
    hasTooltip,
    longTrim,
    seniority,
    endAdornment,
  }) => {
    const isExpanding = projectCount || additionalExpandComponent;
    const { t } = useTranslation();

    return (
      <section className={clsx(styles.member, className)}>
        <section className={clsx(styles.tile, tileClassName, expanded && styles.tileExpended)}>
          {showAvatar && (
            <Link
              to={links.ViewTeamMember({ id: id! })}
              disableNavigation={disableNavigation}
              className={clsx(!disableNavigation && styles.headingLinkIcon, 'mr-12')}
            >
              <Avatar
                avatarTitle={getAcronym(first_name, last_name)}
                size={SIZES.md}
                showIndicator={employment_type === EmploymentType.Contractor}
                className={clsx(!disableNavigation && styles.headingAvatar)}
                color={color}
              />
            </Link>
          )}
          <section
            className={clsx(
              styles.heading,
              headingClassName,
              showAvatar && (longTrim ? styles.trimmedLong : styles.trimmed),
            )}
          >
            <div
              className={clsx('flex', 'align-items-center', styles.trimmedLong, {
                [styles.longTrim]: showAvatar && longTrim,
                [styles.trimmed]: showAvatar && !longTrim,
                [styles.mediumTrim]: onHide && longTrim,
              })}
            >
              <Link to={links.ViewTeamMember({ id: id! })} disableNavigation={disableNavigation}>
                <Tooltip
                  title={`${first_name} ${last_name}`}
                  placement="top"
                  hideTooltip={!hasTooltip}
                  textClassName={clsx(
                    styles.headingTitle,
                    headingTitleClassName,
                    showAvatar && (longTrim ? styles.trimmedLong : styles.trimmed),
                  )}
                >
                  {first_name} {last_name}
                </Tooltip>
              </Link>
              {endAdornment}
            </div>

            {onDelete && (
              <Menu
                onDelete={onDelete}
                deleteConfirmOptions={deleteConfirmOptions}
                floatOptionsClassName={floatOptionsClassName}
              />
            )}
            <section className={clsx(styles.tileInfo, { [styles.mediumTrim]: onHide && longTrim })}>
              {job_title && (
                <span>
                  <Tooltip
                    title={seniority ? `${job_title}, ${seniority}` : job_title}
                    placement="top"
                    textClassName={clsx('overflowed-text', subtitleClassName, {
                      [styles.maxWidth60]: specialization && projectCount,
                      [styles.maxWidth83]: specialization && !projectCount,
                      [styles.maxWidth134]: !specialization && projectCount,
                      [styles.maxWidth134]: !specialization && !projectCount,
                    })}
                  >
                    {seniority ? `${job_title}, ${seniority}` : job_title}
                  </Tooltip>
                </span>
              )}
              {specialization ? (
                <span className={styles.specializationChip}>
                  <Tooltip
                    textClassName={clsx('overflowed-text', {
                      [styles.maxWidth60]: job_title && projectCount,
                      [styles.maxWidth83]: job_title && !projectCount,
                      [styles.maxWidth134]: !job_title && projectCount,
                      [styles.maxWidth134]: !job_title && !projectCount,
                    })}
                    title={specialization}
                    placement="top"
                  >
                    {specialization}
                  </Tooltip>
                </span>
              ) : undefined}
              {!!projectCount && (
                <div className={styles.tileInfoProjects}>
                  <ProjectsLink className={clsx(styles.iconColor, 'mr-4')} />
                  {projectCount}
                </div>
              )}
            </section>
          </section>

          {onHide && (
            <Tooltip
              className="ml-6"
              title={t('resourcePlanning.hide.memberHideTooltip')!}
              placement="top"
              alwaysShowTooltip
            >
              <IconButton onClick={onHide} className="p-6">
                <EyeClosed className={styles.iconColor} />
              </IconButton>
            </Tooltip>
          )}

          {onExpand && (
            <IconButton
              className={clsx(styles.chevronIcon, !!(isExpanding ?? true) && styles.visible)}
              onClick={(e) => {
                (isExpanding ?? true) && onExpand(id ?? null);
                e.stopPropagation();
              }}
            >
              <ChevronIcon className={clsx({ [styles.expanded]: expanded })} />
            </IconButton>
          )}
        </section>

        {expanded && additionalExpandComponent}
      </section>
    );
  },
);

MemberTile.displayName = 'MemberTile';
