import React, { FC } from 'react';
import { Avatar, SIZES, Tooltip } from 'components';
import { useErrorMsgBuilder, usePermissions } from 'hooks';
import { useProjectMembershipsQuery } from 'generated/graphql';
import Button from '@material-ui/core/Button';
import { ActionsType } from 'generated/types';
import { getAcronym, graphqlOnError } from 'utils';
import { useAuth } from 'contexts';
import { ApolloError } from '@apollo/client/errors';

import styles from './styles.module.scss';

interface Props {
  projectId: string;
  onOpenAccessMenu: () => void;
}

export const SharedAccessSetting: FC<Props> = ({ projectId, onOpenAccessMenu }) => {
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { hasAccess } = usePermissions({ projectId });

  const { data: { projectMemberships = [] } = {} } = useProjectMembershipsQuery({
    onError: (err: ApolloError) => {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      data: { projectId },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !hasAccess(ActionsType.ShareProject),
  });

  const memberships = projectMemberships.slice(0, 5);
  const moreMembershipsLabel = projectMemberships.length - 5 <= 0 ? '' : `+${projectMemberships.length - 5}`;
  const isOneMembership = memberships.length === 1;

  return (
    <Button
      variant="text"
      color="secondary"
      onClick={onOpenAccessMenu}
      className={styles.button}
      classes={{ label: styles.label }}
    >
      <div className={isOneMembership ? '' : styles.avatars}>
        {memberships.map(({ color, id, memberFirstName, memberLastName }, index) => (
          <Tooltip
            title={`${memberFirstName} ${memberLastName}`}
            className={styles.avatarBox}
            key={id}
            style={isOneMembership ? {} : { zIndex: index + 1, marginRight: -10 }}
            alwaysShowTooltip
          >
            <Avatar avatarTitle={getAcronym(memberFirstName, memberLastName)} size={SIZES.sm} color={color} />
          </Tooltip>
        ))}
      </div>
      {moreMembershipsLabel ? <span className="ml-2">{moreMembershipsLabel}</span> : ''}
    </Button>
  );
};
