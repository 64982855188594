import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { CountChip, Popover, Tooltip } from 'components';
import { useIsOpen } from 'hooks';
import { DownTriangle } from 'icons';

import styles from './styles.module.scss';

export interface ChangeOptionProps {
  id: string;
  isSelected: boolean;
}

type Option = { id: string; name: string };

interface Props<T> {
  title: string;
  value?: string[];
  onChange: (value: string[]) => void;
  options: T[];
}

export const FilterCheckboxPopover = <T extends Option>({ title, value, onChange, options }: Props<T>) => {
  const [isOpen, onOpen, onClose] = useIsOpen();
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<string[]>(value || []);

  const onChangeOption = ({ id, isSelected }: ChangeOptionProps) => {
    setSelectedOptions((prev) => {
      if (!isSelected) return [...prev, id];

      return prev.filter((valueId) => valueId !== id);
    });
  };

  const label = options?.find(({ id }) => id === value?.[0])?.name || '';
  const selectedAdditionalOptionsCount =
    value && value.length > 1 ? <CountChip count={value.length - 1} className="ml-4" /> : null;

  useEffect(() => {
    setSelectedOptions(value || []);
  }, [value]);

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setSelectedOptions(value || []);
      }}
      buttonClassName="h-auto p-0"
      button={
        value?.length ? (
          <Button variant="outlined" color="secondary" className={styles.valueButton} onClick={onOpen}>
            <Tooltip title={label} textClassName={styles.tooltipTitle}>
              {label}
            </Tooltip>
            {selectedAdditionalOptionsCount}
            <DownTriangle />
          </Button>
        ) : (
          <Button variant="text" color="secondary" className={styles.filterButton} onClick={onOpen}>
            {title}
            <DownTriangle />
          </Button>
        )
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <div className={styles.box}>
        <span className={styles.title}>{title}</span>
        <div className={clsx('form', styles.form)}>
          <div className={styles.formBox}>
            {options?.map(({ id, name }) => {
              const isSelected = selectedOptions.includes(id);

              return (
                <section className={styles.optionBox} key={id}>
                  <FormControlLabel
                    control={<Checkbox checked={isSelected} color="primary" />}
                    label={name}
                    onChange={() => onChangeOption({ id, isSelected })}
                    className="ml-0"
                    classes={{ label: styles.checkboxLabelText }}
                  />
                </section>
              );
            })}
          </div>

          <div className={styles.buttons}>
            <Button
              onClick={() => {
                onChange(selectedOptions);
                onClose();
              }}
            >
              {t('common.filter.apply')}
            </Button>

            <Button
              color="secondary"
              variant="text"
              onClick={() => {
                setSelectedOptions([]);
                onChange([]);
                onClose();
              }}
            >
              {t('common.filter.clear')}
            </Button>
          </div>
        </div>
      </div>
    </Popover>
  );
};
