import React, { FC, memo } from 'react';
import { CompanyIntegrationDataFragment } from 'generated/types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Avatar, Field, SIZES } from 'components';
import { getAcronym } from 'utils';

interface Props {
  integration: CompanyIntegrationDataFragment;
  hideRecordTypes?: boolean;
}

export const IntegrationFields: FC<Props> = memo(({ integration, hideRecordTypes }) => {
  const { t } = useTranslation();
  const { connectedByMemberName, connectedByMemberColor, created_at, integrationOptions } = integration;
  const creatorAcronym = getAcronym(...(connectedByMemberName || '').split(' '));

  const connectedBy = (
    <Field
      label={t('settings.integrations.integration.connectedBy')}
      value={
        <>
          <Avatar avatarTitle={creatorAcronym} size={SIZES.xs} className="mr-4" color={connectedByMemberColor} />
          {connectedByMemberName}
        </>
      }
    />
  );

  const connectedByDate = (
    <Field
      label={t('settings.integrations.integration.connectedDate')}
      value={format(new Date(created_at), 'LLL d, h:mm a')}
    />
  );

  const recordTypes = (
    <Field
      label={t('settings.integrations.integration.recordTypes')}
      value={integrationOptions.map(({ integrationOption }) => integrationOption).join(', ')}
    />
  );

  return (
    <>
      {connectedBy}
      {connectedByDate}
      {!hideRecordTypes && recordTypes}
    </>
  );
});

IntegrationFields.displayName = 'IntegrationFields';
