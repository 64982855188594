import React, {
  createRef,
  CSSProperties,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ExtractArray } from 'react-router-hoc/lib/types';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import sortBy from 'lodash/sortBy';
import {
  add,
  differenceInCalendarDays,
  eachWeekendOfInterval,
  getUnixTime,
  isWithinInterval,
  startOfDay,
} from 'date-fns';

import { links } from 'App';
import { GROUP_MEMBERS_BY } from 'consts';
import { localStorageManager } from 'services';
import { LeaveGradientIcon, PlusIcon, ResourcePlanningStateIcon, ResourcePlanningStateLoadingIcon } from 'icons';
import { usePermissions, useTrackScreenView } from 'hooks';
import { useAuth, useBaseLayoutContext, useTimelineContext } from 'contexts';
import { AbsoluteSpinner, HideMembersMenu, Link, MemberTile, Portal } from 'components';

import { useQueryParams } from 'utils/useQueryParams';
import { getStartEndPeriodDates } from 'views/ResourcePlanning/utils/days';
import { useResourcePlanningMembersQuery } from 'generated/graphql';
import {
  ActionsType,
  ResourcePlanningMember,
  ResourcePlanningMembersDataFragment,
  ResourcePlanningMembersQuery,
} from 'generated/types';
import { ModalModeEnum, ModuleName, ScreenName } from 'types';

import { Filters, TeamGroupingTypesFilter } from '../../types';
import { Availability } from '../Availability';
import { MemberLeaves } from '../MemberLeaves';
import { RowsAccordion } from './RowsAccordion';
import { MemberAssignments } from '../Assignments';
import {
  availabilityFilter,
  getGroupedRowAssignmentsLength,
  getTeamGroupedData,
  useExpandRows,
  useHideMembers,
  useHolidays,
  useResourcePlanningFilters,
  useScrollExpandedIntoView,
} from 'views/ResourcePlanning/utils';
import { getTeamRowAccordionTitle } from 'views/ResourcePlanning/utils/getTeamRowAccordionTitle';
import { CapacityMarker } from './components';

import styles from './styles.module.scss';
import clsx from 'clsx';

type Props = {
  date: Date;
  paginationPeriod: [Date, Date];
  onChange?: (date: Date) => void;
};

type RowProps = {
  date: Date;
  member: ResourcePlanningMembersDataFragment & { available: boolean };
  onHide?: () => void;
  onExpand?: () => void;
  isHidden?: boolean;
  isExpanded?: boolean;
  shouldScrollAfterExpand?: boolean;
};

const Row = memo<RowProps>(({ date, member, onHide, onExpand, isHidden, isExpanded, shouldScrollAfterExpand }) => {
  const [expanded, setExpanded] = useState(isExpanded || false);
  const [isHiding, setIsHiding] = useState(false);
  const { params } = useRouteMatch();
  const { push } = useHistory();
  const queryParams = useQueryParams();
  const { t } = useTranslation();
  const { timelinePeriod } = useTimelineContext();
  const { hasAccess } = usePermissions();
  const rowRef = createRef<HTMLDivElement>();
  useScrollExpandedIntoView({ ref: rowRef, expanded, shouldScrollAfterExpand });

  const [startTimePeriod, endTimePeriod] = useMemo(() => getStartEndPeriodDates(date, timelinePeriod), [
    date,
    timelinePeriod,
  ]);

  const memberData = { ...member, member_leave: member.member_leave || [] };

  const memberHasLeaves = memberData.member_leave.length > 0;

  const projects = useMemo(() => {
    return member.projects?.filter(
      (project) =>
        (project?.assignment?.length &&
          project.assignment.some(
            (assignment) =>
              +new Date(assignment?.startDate?.toString() ?? '') <= +endTimePeriod &&
              +new Date(assignment?.endDate?.toString() ?? '') >= +startTimePeriod,
          )) ||
        (project.requests?.length &&
          project.requests?.some(
            (request) =>
              +new Date(request?.data.startDate?.toString() ?? '') <= +endTimePeriod &&
              +new Date(request?.data.endDate?.toString() ?? '') >= +startTimePeriod,
          )),
    );
  }, [member.projects]);

  const projectsSection = (
    <section className={styles.projectsSection}>
      {memberHasLeaves && (
        <div className={styles.timelineRowMemberLeaves}>
          <div className={styles.timelineRowMemberLeavesIconWrapper}>
            <LeaveGradientIcon />
          </div>
          <p>{t('resourcePlanning.onLeave')}</p>
        </div>
      )}
      {projects?.map((project) => (
        <Link to={links.ProjectDetail({ id: project?.id })} key={project?.id}>
          <div
            style={
              {
                '--assignments': expanded ? getGroupedRowAssignmentsLength(project) : 0,
              } as CSSProperties
            }
            className={styles.timelineRowMemberProject}
          >
            <div className={styles.timelineRowMemberProjectLogo} style={{ backgroundColor: project.color }} />
            <p>{project?.name}</p>
          </div>
        </Link>
      ))}
      {hasAccess(ActionsType.CreateAssignments) && (
        <Button
          startIcon={<PlusIcon className={styles.timelineSidebarButtonIcon} />}
          variant="text"
          color="inherit"
          className={styles.timelineSidebarButton}
          onClick={() =>
            push(links.ResourcePlanning({ ...params, ...queryParams, memberId: member.id, mode: ModalModeEnum.manage }))
          }
        >
          {t('resourcePlanning.addNewAssignment')}...
        </Button>
      )}
      {!hasAccess(ActionsType.CreateAssignments) && hasAccess(ActionsType.SubmitAssignmentRequest) && (
        <Button
          startIcon={<PlusIcon className={styles.timelineSidebarButtonIcon} />}
          variant="text"
          color="inherit"
          className={styles.timelineSidebarButton}
          onClick={() =>
            push(
              links.ResourcePlanning({ ...params, ...queryParams, memberId: member.id, mode: ModalModeEnum.request }),
            )
          }
        >
          {t('resourcePlanning.addNewAssignment')}...
        </Button>
      )}
      {hasAccess(ActionsType.CreateLeaves) && (
        <Button
          startIcon={<PlusIcon className={styles.timelineSidebarButtonIcon} />}
          variant="text"
          color="inherit"
          className={styles.timelineSidebarButton}
          onClick={() =>
            push(
              links.ResourcePlanning({
                ...params,
                ...queryParams,
                date: getUnixTime(date),
                mode: ModalModeEnum.leave,
                memberId: member.id,
              }),
            )
          }
        >
          {t('resourcePlanning.addNewLeave')}...
        </Button>
      )}
    </section>
  );

  const onLeave = useCallback(
    (id: string) => {
      hasAccess(ActionsType.EditLeaves)
        ? push(
            links.ResourcePlanning({
              ...params,
              ...queryParams,
              date: getUnixTime(date),
              mode: ModalModeEnum.leave,
              id,
            }),
          )
        : undefined;
    },
    [date],
  );

  return (
    <section
      onAnimationEnd={() => setIsHiding(false)}
      className={clsx(styles.timelineRow, isHiding && styles.hiddenMember, !isHiding && isHidden && 'd-none')}
      key={member.id}
      ref={rowRef}
    >
      <MemberTile
        expanded={expanded}
        projectCount={projects?.length || 0}
        onExpand={() => {
          setExpanded(!expanded);
          onExpand?.();
        }}
        onHide={() => {
          onHide?.();
          setIsHiding(true);
        }}
        data={member}
        showAvatar
        longTrim
        className={styles.timelineSidebar}
        tileClassName={styles.timelineSidebarTile}
        additionalExpandComponent={projectsSection}
        endAdornment={
          <CapacityMarker
            memberCapacity={member.capacity}
            projects={projects}
            start={startTimePeriod}
            end={endTimePeriod}
          />
        }
      />
      <section className={styles.timelineAvailability}>
        <Availability onLeave={onLeave} date={date} member={(memberData as unknown) as ResourcePlanningMember} />
        {expanded && (
          <>
            {memberHasLeaves && <MemberLeaves date={date} member={memberData} onLeave={onLeave} />}
            {projects?.map(
              (project) =>
                project && <MemberAssignments key={project?.id} date={date} project={project} memberId={member.id} />,
            )}
          </>
        )}
      </section>
    </section>
  );
});

Row.displayName = 'Timeline.Team.Row';

type MemberType = ResourcePlanningMembersQuery['resourcePlanningMembers'][number] & { available: boolean };

type MembersProps = {
  date: Date;
  paginationPeriod: [Date, Date];
  data?: Array<MemberType>;
};

const Members = memo<MembersProps>(({ data, date, paginationPeriod }) => {
  const { t } = useTranslation();
  const {
    availability,
    groupingType = localStorageManager.getItem(GROUP_MEMBERS_BY) ?? TeamGroupingTypesFilter.availability,
  } = useQueryParams<Filters>();
  const [collapsedAccordions, setCollapsedAccordions] = useState<string[]>([]);
  const { hiddenMembersIds, onHideMember, onShowMember, onShowAllMember, getHiddenMembers } = useHideMembers();
  const { checkIsRowExpanded, onExpandRow } = useExpandRows();

  useEffect(() => {
    setCollapsedAccordions([]);
  }, [groupingType]);

  const updateCollapsedAccordion = (id: string) => {
    setCollapsedAccordions((prev) => {
      return prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id];
    });
  };

  const members = useMemo(() => {
    return data?.filter(({ available }) => {
      return !availability || availabilityFilter[availability](available);
    });
  }, [data, availability]);

  const hiddenMembers: ResourcePlanningMembersDataFragment[] = useMemo(() => {
    return getHiddenMembers(members || []);
  }, [members, hiddenMembersIds]);

  const sortedMembers = useMemo(
    () => sortBy(members, ({ first_name, last_name }) => first_name?.concat(last_name ?? '')),
    [members],
  );

  const groupedData = useMemo(
    () => getTeamGroupedData({ groupBy: groupingType, members: sortedMembers, t, paginationPeriod }),
    [groupingType, sortedMembers, paginationPeriod],
  );

  return (
    <>
      <Portal wrapperId="hide-team-member-menu">
        <HideMembersMenu hiddenMembers={hiddenMembers} onShowMember={onShowMember} onShowAllMember={onShowAllMember} />
      </Portal>
      {groupingType === TeamGroupingTypesFilter.none
        ? sortedMembers?.map((member, index) => {
            const isHidden = hiddenMembersIds.includes(member.id);
            const isOneOfLastRows = index > sortedMembers.length - 4;

            return (
              <Row
                isHidden={isHidden}
                date={date}
                member={member}
                key={member.id}
                onHide={onHideMember(member.id)}
                shouldScrollAfterExpand={isOneOfLastRows}
              />
            );
          })
        : groupedData.map(
            ([key, value]) =>
              value.length > 0 && (
                <RowsAccordion
                  title={getTeamRowAccordionTitle({ groupBy: groupingType, key, members: value, t })}
                  key={key}
                  collapsed={collapsedAccordions.includes(key)}
                  onChangeCollapse={() => updateCollapsedAccordion(key)}
                >
                  {value?.map((member, index) => {
                    const isHidden = hiddenMembersIds.includes(member.id);
                    const isOneOfLastRows = index > value.length - 4;

                    return (
                      <Row
                        isHidden={isHidden}
                        onHide={onHideMember(member.id)}
                        date={date}
                        member={member}
                        key={member.id}
                        shouldScrollAfterExpand={isOneOfLastRows}
                        isExpanded={checkIsRowExpanded(member.id)}
                        onExpand={onExpandRow(member.id)}
                      />
                    );
                  })}
                </RowsAccordion>
              ),
          )}
    </>
  );
});

Members.displayName = 'Timeline.Members';

const WORKING_DAY_HOURS = 8;

export const TeamTimeline = memo<PropsWithChildren<Props>>(({ date, paginationPeriod }) => {
  const { userData } = useAuth();
  const { search, showRequests } = useQueryParams<Filters>();
  const { hasAccess } = usePermissions();
  const { isNavBarExpanded } = useBaseLayoutContext();
  const { t } = useTranslation();
  const { timelinePeriod } = useTimelineContext();

  const { filters } = useResourcePlanningFilters();
  const { membersTypes, membersSeniority, membersSpecialization, membersSkills } = filters;
  useTrackScreenView(ModuleName.resourcePlanning, ScreenName.team, {
    additionalProperties: { View: timelinePeriod },
    deps: [timelinePeriod],
  });

  const [startPeriodDate, endPeriodDate] = paginationPeriod;

  const { data, loading } = useResourcePlanningMembersQuery({
    variables: {
      companyId: userData?.company.id ?? '',
      paginationAssignmentData: {
        start: startPeriodDate.toISOString(),
        end: endPeriodDate.toISOString(),
      },
      filterData: {
        employmentType: membersTypes?.length ? membersTypes : undefined,
        seniority: membersSeniority?.length ? membersSeniority : undefined,
        skill: membersSkills?.length ? membersSkills : undefined,
        specialization: membersSpecialization?.length ? membersSpecialization : undefined,
      },
      withSpecialization: true,
      withLeaves: hasAccess(ActionsType.ViewLeaves),
      withRequests: String(showRequests) !== 'false',
      searchValue: search,
    },
    fetchPolicy: 'cache-and-network',
  });

  const allHolidays = useHolidays();
  const cachedMembers = useRef<any>();
  const resourcePlanningMembers = useMemo(
    () => data?.resourcePlanningMembers.filter(({ archived_at }) => !archived_at),
    [data?.resourcePlanningMembers],
  );

  useEffect(() => {
    if (resourcePlanningMembers)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      cachedMembers.current = resourcePlanningMembers.map(({ projects, ...rest }) => ({ ...rest }));
  }, [resourcePlanningMembers]);

  const members = useMemo(() => {
    if (!resourcePlanningMembers) return cachedMembers.current;

    const days =
      Math.abs(differenceInCalendarDays(startPeriodDate, endPeriodDate)) -
      eachWeekendOfInterval({ start: startPeriodDate, end: endPeriodDate }).length;

    const holidays = Array.from(allHolidays).filter((date) =>
      isWithinInterval(new Date(date), {
        start: startPeriodDate,
        end: endPeriodDate,
      }),
    ).length;

    const hours = (days - holidays) * WORKING_DAY_HOURS;

    return resourcePlanningMembers.map((member) => {
      const memberHours = member.projects
        .reduce(
          (acc, project) => acc.concat(project.assignment),
          [] as ExtractArray<typeof member.projects>['assignment'],
        )
        .reduce((acc, assignment) => {
          const [start, end] = [
            new Date(String(assignment?.startDate).slice(0, -5)),
            new Date(String(assignment?.endDate).slice(0, -5)),
          ];

          if (+end < +startPeriodDate || +start > +endPeriodDate) return acc;

          const [startRange, endRange] = [
            +start < +startPeriodDate ? startPeriodDate : start,
            +end > +endPeriodDate ? startOfDay(endPeriodDate) : end,
          ];

          const days = Math.abs(differenceInCalendarDays(startRange, add(endRange, { days: 1 })));

          const weekends = eachWeekendOfInterval({
            start: startRange,
            end: endRange,
          }).length;

          const holidays = Array.from(allHolidays).filter((date) =>
            isWithinInterval(new Date(date), {
              start: startRange,
              end: endRange,
            }),
          ).length;

          return acc + (days - holidays - weekends) * (assignment?.allocationTimeAmount ?? 0);
        }, 0);

      return { ...member, available: hours - memberHours > 1 };
    });
  }, [resourcePlanningMembers, date, endPeriodDate, startPeriodDate, allHolidays]);

  return (
    <>
      {loading && members?.length && (
        <div className={clsx(styles.timelineLoader, isNavBarExpanded && styles.timelineLoadingExpandedSidebar)}>
          <AbsoluteSpinner />
        </div>
      )}
      {loading && !members?.length && (
        <div className={clsx(styles.timelineLoadingState, isNavBarExpanded && styles.timelineLoadingExpandedSidebar)}>
          <ResourcePlanningStateLoadingIcon />
          <span className="mt-24 mb-6">{t('resourcePlanning.emptyState.hangTight')}</span>
          <span>{t('resourcePlanning.emptyState.loadingText')}</span>
        </div>
      )}
      {!loading && !members?.length && (
        <div className={clsx(styles.timelineLoadingState, isNavBarExpanded && styles.timelineLoadingExpandedSidebar)}>
          <ResourcePlanningStateIcon />
          <span className="mt-24 mb-6">{t('resourcePlanning.emptyState.nothingFound')}</span>
        </div>
      )}

      <div className={styles.timelineContainer}>
        {!members?.length && loading ? (
          ''
        ) : (
          <div className={styles.countCell}>
            {members.length} {t(`resourcePlanning.${members?.length === 1 ? 'member' : 'members'}`)}
          </div>
        )}

        <Members date={date} data={members} paginationPeriod={paginationPeriod} />
      </div>
    </>
  );
});

TeamTimeline.displayName = 'Timeline.Team';
