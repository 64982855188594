import React, { FC, useCallback } from 'react';
import { Avatar, ChangeFiltersData, FilterCheckboxPopover, FilterTagsPopover, MemberTag, SIZES } from 'components';
import { useFiltersData } from 'components/CommonFiltersPopover/useFiltersData';
import { useTranslation } from 'react-i18next';
import { Member } from 'generated/types';
import { getAcronym, getFullName } from 'utils';
import { CommonFilterLabel, CommonFilterOptions } from 'types';
import mixpanel from 'mixpanel-browser';
import { Button } from '@material-ui/core';

import styles from './styles.module.scss';

interface Props {
  onChange: (data: Partial<ChangeFiltersData>) => void;
  filtersOptions: CommonFilterOptions[];
}

export const CommonFiltersSection: FC<Props & ChangeFiltersData> = ({
  onChange,
  filtersOptions,
  employmentType = [],
  seniority = [],
  specialization = [],
  reportingTo = [],
  type = [],
  client = [],
  pm = [],
  skill = [],
}) => {
  const { t } = useTranslation();

  const {
    employmentTypeOptions,
    specializationsOptions,
    senioritiesOptions,
    membersOptions,
    clientsOptions,
    projectTypeOptions,
    skillsOptions,
  } = useFiltersData({
    filtersOptions,
    values: {
      employmentType,
      seniority: [],
      specialization: [],
      reportingTo: [],
      client: [],
      pm: [],
      skill: [],
      type,
    },
  });

  const isEmptyFilters =
    !employmentType.length &&
    !seniority.length &&
    !specialization.length &&
    !reportingTo.length &&
    !type.length &&
    !client.length &&
    !skill.length &&
    !pm.length;

  const onTrackChanges = (label: CommonFilterLabel) =>
    mixpanel.track('Filter applied', {
      Filter: label,
    });

  const getMemberOptionLabel = useCallback(
    ({ first_name, last_name }: Partial<Member>) => getFullName(first_name, last_name, t('notApplicable')),
    [],
  );
  const getMemberLabelPrefix = useCallback(
    ({ first_name, last_name, color }: Partial<Member>) => (
      <Avatar className="mr-6" avatarTitle={getAcronym(first_name, last_name)} size={SIZES.xs} color={color} />
    ),
    [],
  );
  const renderMemberTag = useCallback(
    (member: Partial<Member>, onDelete?: () => void) => (
      <MemberTag
        key={member.id}
        firstName={member.first_name!}
        lastName={member.last_name!}
        avatarColor={member.color}
        color=""
        className={styles.tag}
        onDelete={onDelete}
      />
    ),
    [],
  );

  return (
    <div className="flex gap-8">
      {filtersOptions.includes(CommonFilterOptions.employmentType) && (
        <FilterCheckboxPopover
          title={t('common.filter.contractType')}
          value={employmentType}
          onChange={(employmentType) => {
            onChange({ employmentType });
            onTrackChanges(CommonFilterLabel.employmentType);
          }}
          options={employmentTypeOptions}
        />
      )}
      {filtersOptions.includes(CommonFilterOptions.seniority) && (
        <FilterCheckboxPopover
          title={t('common.filter.seniority')}
          value={seniority}
          onChange={(seniority) => {
            onChange({ seniority });
            onTrackChanges(CommonFilterLabel.seniority);
          }}
          options={senioritiesOptions}
        />
      )}
      {filtersOptions.includes(CommonFilterOptions.specialization) && (
        <FilterCheckboxPopover
          title={t('common.filter.specialization')}
          value={specialization}
          onChange={(specialization) => {
            onChange({ specialization });
            onTrackChanges(CommonFilterLabel.specialization);
          }}
          options={specializationsOptions}
        />
      )}
      {filtersOptions.includes(CommonFilterOptions.reportingTo) && (
        <FilterTagsPopover
          title={t('common.filter.reportingTo')}
          placeholder={t('common.filter.selectReportingTo')}
          value={reportingTo}
          onChange={(reportingTo) => {
            onChange({ reportingTo });
            onTrackChanges(CommonFilterLabel.reportingTo);
          }}
          options={membersOptions}
          getOptionLabel={getMemberOptionLabel}
          getLabelPrefix={getMemberLabelPrefix}
          renderTag={renderMemberTag}
        />
      )}
      {filtersOptions.includes(CommonFilterOptions.type) && (
        <FilterCheckboxPopover
          title={t('common.filter.projectType')}
          value={type}
          onChange={(type) => {
            onChange({ type });
            onTrackChanges(CommonFilterLabel.type);
          }}
          options={projectTypeOptions}
        />
      )}
      {filtersOptions.includes(CommonFilterOptions.client) && (
        <FilterTagsPopover
          title={t('common.filter.client')}
          placeholder={t('common.filter.selectClient')}
          value={client}
          onChange={(client) => {
            onChange({ client });
            onTrackChanges(CommonFilterLabel.client);
          }}
          options={clientsOptions}
        />
      )}
      {filtersOptions.includes(CommonFilterOptions.skill) && (
        <FilterTagsPopover
          title={t('common.filter.skills')}
          placeholder={t('common.filter.selectSkills')}
          value={skill}
          onChange={(skill) => {
            onChange({ skill });
            onTrackChanges(CommonFilterLabel.skill);
          }}
          options={skillsOptions}
        />
      )}
      {filtersOptions.includes(CommonFilterOptions.pm) && (
        <FilterTagsPopover
          title={t('common.filter.projectManager')}
          placeholder={t('common.filter.selectProjectManager')}
          value={pm}
          onChange={(pm) => {
            onChange({ pm });
            onTrackChanges(CommonFilterLabel.pm);
          }}
          options={membersOptions}
          getOptionLabel={getMemberOptionLabel}
          getLabelPrefix={getMemberLabelPrefix}
          renderTag={renderMemberTag}
        />
      )}
      <Button
        variant="text"
        color="secondary"
        className={isEmptyFilters ? 'd-none' : ''}
        onClick={() => {
          const emptyFilters = filtersOptions.reduce((acc, filter) => ({ ...acc, [filter]: undefined }), {});
          onChange(emptyFilters);
        }}
      >
        {t('common.filter.clearFilters')}
      </Button>
    </div>
  );
};
