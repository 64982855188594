import React, { FC, useEffect, useMemo, useState } from 'react';
import { AnimationCountChip, Avatar, EmptyState, MemberTag, RightDrawer, Search, SIZES } from 'components';
import { ArrowDown, Eye, EyeClosed } from 'icons';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTeamMembersQuery } from 'generated/graphql';
import { getAcronym, graphqlOnError, searchFilter, sortByField } from 'utils';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';

import styles from './styles.module.scss';
import { ASC, FIRST_NAME, LAST_NAME } from 'consts';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: string[]) => void;
  excludeMembers: string[];
}

const SELECT_TAG_COLOR = '#ebedee';

export const HideMembersDrawer: FC<Props> = ({ onClose, open, onSubmit, excludeMembers }) => {
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedExcludeMembers, setSelectedExcludeMembers] = useState<string[]>(excludeMembers || []);

  const handleSubmit = () => onSubmit(selectedExcludeMembers);

  const { data: { members = [] } = {}, loading: membersLoading } = useTeamMembersQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const sortedMembers = useMemo(() => sortByField(members, ASC, FIRST_NAME, LAST_NAME), [members]);

  const membersOptions = useMemo(() => {
    return !searchValue
      ? sortedMembers
      : sortedMembers.filter(({ first_name, last_name }) => searchFilter(searchValue, first_name, last_name));
  }, [searchValue, members]);

  useEffect(() => {
    if (open) {
      setSelectedExcludeMembers(excludeMembers || []);
      setSearchValue('');
    }
  }, [open]);

  const onSearchChange = (value?: string) => {
    setSearchValue(value || '');
  };

  const clearFilters = () => setSelectedExcludeMembers([]);
  const onHide = (id: string) => setSelectedExcludeMembers((prev) => [...prev, id]);
  const onShow = (id: string) => setSelectedExcludeMembers((prev) => prev.filter((item) => item !== id));

  const filterCount = selectedExcludeMembers.length || 0;
  const emptyLoadingState = membersLoading ? (
    <CircularProgress size={64} style={{ color: '#24343D' }} />
  ) : (
    <EmptyState className="mt-40" />
  );

  return (
    <RightDrawer
      open={open}
      onClose={onClose}
      boxClassName={styles.container}
      title={<div className="flex align-items-center">{t('common.filter.hideMembers')}</div>}
    >
      <div className={styles.searchBox}>
        <Search
          onChange={onSearchChange}
          placeholder={t('common.filter.searchMembers')}
          value={searchValue}
          className={styles.search}
          searchWidth="100%"
        />
      </div>

      <form className={clsx('form', styles.form)}>
        <div className="flex flex-column mb-24">
          {!membersOptions.length || membersOptions.length === selectedExcludeMembers.length
            ? emptyLoadingState
            : membersOptions.map(({ id, color, first_name, last_name }) => {
                const isHidden = selectedExcludeMembers.includes(id);
                return (
                  <div className={clsx('flex justify-content-between mb-8', isHidden && 'd-none')} key={id}>
                    <div className="flex align-items-center gap-8">
                      <Avatar avatarTitle={getAcronym(first_name, last_name)} size={SIZES.sm} color={color} />
                      <span className={styles.memberTitle}>{`${first_name} ${last_name}`}</span>
                    </div>
                    <Button
                      variant="text"
                      color="secondary"
                      className={styles.hideButton}
                      startIcon={<EyeClosed />}
                      onClick={() => onHide(id)}
                    >
                      {t('common.filter.hide')}
                    </Button>
                  </div>
                );
              })}
        </div>

        <div className={styles.bottomBlock}>
          <Accordion className={styles.accordion}>
            <AccordionSummary
              className={styles.accordionSummary}
              expandIcon={<ArrowDown />}
              aria-controls="row-accordion-content"
              id="row-accordion-header"
              classes={{
                expandIcon: styles.accordionIcon,
              }}
            >
              <span className="flex align-items-center">
                {t('common.filter.hidden')}
                <AnimationCountChip className={clsx(!filterCount && styles.emptyCountChip)} count={filterCount} />
              </span>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetails}>
              {sortedMembers.map(({ id, color, first_name, last_name }) => {
                const isHidden = selectedExcludeMembers.includes(id);
                return (
                  <MemberTag
                    key={id}
                    firstName={first_name!}
                    lastName={last_name!}
                    avatarColor={color}
                    color={SELECT_TAG_COLOR}
                    className={clsx(styles.tag, !isHidden && 'd-none')}
                    onDelete={() => onShow(id)}
                  />
                );
              })}
            </AccordionDetails>
          </Accordion>

          <div className={styles.buttons}>
            <Button onClick={handleSubmit}>{t('forms.done')}</Button>
            <Button color="secondary" variant="outlined" onClick={onClose}>
              {t('common.filter.cancel')}
            </Button>
            <Button
              color="secondary"
              variant="text"
              className="ml-auto"
              onClick={clearFilters}
              startIcon={<Eye className={styles.showAllIcon} />}
            >
              {t('common.filter.showAll')}
            </Button>
          </div>
        </div>
      </form>
    </RightDrawer>
  );
};
