import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  ActorPermissionsDocument,
  CompanyIntegrationDataFragmentDoc,
  useCreateIntegrationConnectionMutation,
} from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { AvailableIntegrationDataFragment } from 'generated/types';
import { FormValues, IntegrationForm } from '../IntegrationForm';
import { useQueryParams } from 'utils/useQueryParams';
import { PeopleForceInfoBox } from '../PeopleForceInfoBox';
import { IntegrationsEnum } from '../../Integrations.enum';
import { Reference } from '@apollo/client';
import { client } from 'graphql-client';

interface Props {
  integration?: AvailableIntegrationDataFragment;
  onCancel: () => void;
}

interface QueryParams {
  code?: string;
  'accounts-server'?: string;
}

export const CreateIntegration: FC<Props> = ({ integration, onCancel }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const queryParams = useQueryParams<QueryParams>();

  const [createIntegrationConnection, { loading }] = useCreateIntegrationConnectionMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted({ createIntegrationConnection }) {
      toast.success(
        t('settings.integrations.notifications.connected', {
          name: createIntegrationConnection?.integration || '',
        }),
      );
      onCancel();
    },
    update(cache, { data }) {
      if (!data?.createIntegrationConnection) return;

      const createdIntegrationRef = cache.writeFragment({
        data: data.createIntegrationConnection,
        fragment: CompanyIntegrationDataFragmentDoc,
      });

      cache.modify({
        fields: {
          companyIntegrations(items: Reference[] = []) {
            return [...items, createdIntegrationRef];
          },
        },
      });

      if (data.createIntegrationConnection.integration === IntegrationsEnum.xero) {
        client.refetchQueries({ include: [ActorPermissionsDocument] });
      }
    },
  });

  useEffect(() => {
    if (integration?.name === IntegrationsEnum.xero) {
      const domain = queryParams?.['accounts-server']?.replace(/https?:\/\//i, '');

      createIntegrationConnection({
        variables: {
          companyId: userData!.company.id,
          data: {
            code: queryParams.code || '',
            domain: domain || null,
            integrationId: integration!.id,
            integrationOptions: [],
          },
        },
      });
    }
  }, []);

  const onCreate = useCallback(({ token, options, dateFormat }: FormValues) => {
    const domain = queryParams?.['accounts-server']?.replace(/https?:\/\//i, '');

    createIntegrationConnection({
      variables: {
        companyId: userData!.company.id,
        data: {
          code: token,
          domain: domain || null,
          integrationId: integration!.id,
          integrationOptions: options,
          dateFormat: dateFormat.id,
        },
      },
    });
  }, []);

  return (
    <IntegrationForm
      infoBox={integration?.name === IntegrationsEnum.peopleForce ? <PeopleForceInfoBox /> : undefined}
      tokenCode={integration?.name === IntegrationsEnum.zohoPeople ? queryParams.code : undefined}
      onSubmit={onCreate}
      onCancel={onCancel}
      options={integration?.options}
      isLoading={loading}
      submitButtonText={t('settings.integrations.form.connect', { name: integration?.name || '' })}
      showDateFormat={integration?.name === IntegrationsEnum.zohoPeople}
    />
  );
};
