import React, { ChangeEvent, KeyboardEvent } from 'react';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteIcon, SearchIcon } from 'icons';

import styles from './styles.module.scss';

interface SearchFieldProps {
  value?: string;
  onChange: (value?: string) => void;
  onBlur?: () => void;
  onReset?: () => void;
  className?: string;
  fixed?: boolean;
  placeholder?: string;
  searchWidth?: number | string;
}

interface StyleProps {
  searchWidth?: number | string;
}

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      width: ({ searchWidth }: StyleProps) => searchWidth || 280,
      border: '1px solid #d6dbdd',
      borderRadius: 4,
      height: 36,

      '& .MuiInputBase-input': {
        fontSize: '14px',
        color: '#050709',
        '&::placeholder': { color: '#85929A' },
      },

      '& fieldset': {
        borderColor: '#ffffff',
      },

      '&.Mui-focused': {
        backgroundColor: '#ffffff',
        borderColor: '#85929a',
        '&:hover': {
          backgroundColor: '#ffffff',

          '& fieldset': {
            border: 'none',
          },
        },
        '& fieldset': {
          border: 'none',
        },
        '& input:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px #ffffff inset',
        },
      },

      '&:hover ': {
        backgroundColor: '#ebedee',
        '& fieldset': {
          borderColor: '#ebedee',
        },
        '& input:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px #ebedee inset',
        },
      },

      '& input': {
        padding: '8px 0',
      },
    },
  },
});

export const Search = ({
  value = '',
  onChange,
  onReset,
  onBlur,
  className,
  placeholder,
  searchWidth,
}: SearchFieldProps) => {
  const { t } = useTranslation();
  const classes = useStyles({ searchWidth });
  const handleSearchFieldChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => onChange(value);

  const handleEnterPress = ({ target, key }: KeyboardEvent) => key === 'Enter' && (target as HTMLInputElement).blur();

  const resetSearch = () => {
    onChange(undefined);
    onReset?.();
  };

  return (
    <TextField
      onChange={handleSearchFieldChange}
      onBlur={onBlur}
      value={value}
      fullWidth={false}
      placeholder={placeholder ?? t('forms.search')}
      className={clsx(classes.root, className)}
      onKeyDown={handleEnterPress}
      id="resizable-search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={styles.inputIcon} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="start"
            className={clsx(styles.clearIcon, value && styles.visible)}
            onClick={resetSearch}
          >
            <DeleteIcon className={styles.inputIcon} />
          </InputAdornment>
        ),
      }}
    />
  );
};
