import { useManagedProjectsOptionsQuery } from 'generated/graphql';
import { graphqlOnError, sortByField } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';
import { QueryFunctionOptions } from '@apollo/client/react/types/types';
import { ASC, PROJECT } from 'consts';

export const useManagedProjectsOptions = (options?: QueryFunctionOptions) => {
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const {
    data: { managedProjects: projects = [] } = {},
    loading: projectsOptionsLoading,
  } = useManagedProjectsOptionsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      isArchived: false,
    },
    skip: options?.skip,
  });
  const projectsOptions = sortByField(projects, ASC, PROJECT);

  return { projectsOptions, projectsOptionsLoading };
};
