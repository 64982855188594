export enum CommonFilterOptions {
  employmentType = 'employmentType',
  seniority = 'seniority',
  specialization = 'specialization',
  reportingTo = 'reportingTo',
  client = 'client',
  pm = 'pm',
  type = 'type',
  skill = 'skill',
}

export enum CommonFilterLabel {
  employmentType = 'contract-type',
  seniority = 'seniority',
  specialization = 'specialization',
  reportingTo = 'reporting-to',
  client = 'client',
  pm = 'project-manager',
  type = 'project-type',
  skill = 'skill',
}
