import React from 'react';
import clsx from 'clsx';
import { Route } from 'react-router-hoc';

import { FormValues, Login as LoginForm, UnauthFormHeading } from 'components';
import { useAuth, useSettings } from 'contexts';
import { useSetAppTitle } from 'hooks/useSetAppTitle';
import { useTranslation } from 'react-i18next';
import { useDeviceTypeByWidth, useTrackScreenView } from 'hooks';
import { ModuleName, ScreenName } from 'types';

export const Login = () => {
  const { handleLogin } = useAuth();
  const { onChangeIsEmployeeMode } = useSettings();
  const { t } = useTranslation();
  useSetAppTitle(t('navigation.login'));
  useTrackScreenView(ModuleName.auth, ScreenName.login, { loading: false, isNoAuthorizedUser: true });
  const { isMobileDevice } = useDeviceTypeByWidth();

  const login = async ({ email, password, remember }: FormValues) => {
    try {
      await handleLogin(email, password, remember);
      onChangeIsEmployeeMode(false);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div className={clsx('form-content', 'col-3', 'col-md-6', isMobileDevice && 'p-0')}>
      <UnauthFormHeading heading="Login" />
      <div className="px-30 pt-24 pb-30">
        <LoginForm onSubmit={login} />
      </div>
    </div>
  );
};

export default Route('/login')(Login);
