export enum TeamTabs {
  active = 'active',
  archived = 'archived',
}

export enum ProjectsTabs {
  active = 'active',
  archived = 'archived',
}

export enum ScenarioTabs {
  active = 'active',
  archived = 'archived',
}

export enum ProjectTabs {
  timeTracking = 'time-tracking',
  expenses = 'expenses',
  milestones = 'milestones',
  assignments = 'assignments',
  files = 'project-files',
  billing = 'billing',
}

export enum MemberTabs {
  compensations = 'compensations',
  financial = 'financial',
  timeTracking = 'time-tracking',
  skills = 'skills',
}

export enum ActivityTabs {
  requestsInbox = 'requests-inbox',
  todos = 'todos',
  activityLog = 'activity-log',
}

export enum InsightsTabs {
  utilization = 'utilization',
  bench = 'bench',
  projects = 'projects',
}

export enum TimeTrackingTabs {
  list = 'list',
  timesheet = 'timesheet',
}

export enum RequestTabs {
  list = 'list',
  details = 'details',
}

export enum BillingTabs {
  list = 'list',
  project = 'project',
}

export enum DrawerQueries {
  rateCardMode = 'ratecard',
  shareProjectsMode = 'share-projects',
  activityHistoryMode = 'activity',
  leavesBalanceMode = 'leaves-balance',
  editMode = 'edit',
  settingMode = 'setting',
  memberMode = 'member',
}
