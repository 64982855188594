import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';

import { graphqlOnError } from 'utils';
import { ExpandedColumn, RemoveConfirmIconButton, RightDrawer, Table, TableActionCell } from 'components';
import { useAuth } from 'contexts';
import {
  useCreateCompensationTypeMutation,
  useDeleteCompensationTypeMutation,
  useEditCompensationTypeMutation,
  useCompensationTypesQuery,
} from 'generated/graphql';
import { useErrorMsgBuilder } from 'hooks';
import { CompensationType } from 'generated/types';
import { GetRouteProps, ModalModeEnum } from 'types';
import { EditIcon, PlusIcon } from 'icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { NewCompensationType, NewCompensationTypeFormValues } from './NewCompensationType';
import type { CompensationTypesRoute } from './index';

type Props = GetRouteProps<typeof CompensationTypesRoute>;

const CompensationTypes: FC<Props> = ({ link, history: { push }, match: { params } }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const { data: { compensationTypes = [] } = {}, loading, refetch } = useCompensationTypesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const compensationTypeToEdit = useMemo(() => {
    if (!params.compensationId) {
      return;
    }

    return compensationTypes.find((type) => type.id === params.compensationId);
  }, [params.compensationId, compensationTypes]);

  const onCloseCreateModal = useCallback(() => {
    push(link({ ...params, mode: undefined }));
  }, [params]);

  const onCloseEditModal = useCallback(() => {
    push(link({ ...params, mode: undefined, compensationId: undefined }));
  }, [params]);

  const [createCompensationType] = useCreateCompensationTypeMutation({
    onCompleted() {
      toast.success(t('compensationTypes.compensationTypeCreatedSuccessfully'));
      onCloseCreateModal();
      refetch();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [editCompensationType] = useEditCompensationTypeMutation({
    onCompleted() {
      toast.success(t('compensationTypes.compensationTypeEditedSuccessfully'));
      onCloseEditModal();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const [deleteCompensationType] = useDeleteCompensationTypeMutation({
    onCompleted() {
      toast.success(t('compensationTypes.compensationTypeDeletedSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteCompensationType) });
        cache.gc();
      }
    },
  });

  const handleDelete = (id: string) =>
    deleteCompensationType({
      variables: {
        compensationTypeId: id,
        companyId: userData!.company.id,
      },
    });

  const onCreate = (values: NewCompensationTypeFormValues) => {
    createCompensationType({
      variables: {
        data: {
          ...values,
        },
        companyId: userData!.company.id,
      },
    });
  };

  const onEdit = (values: NewCompensationTypeFormValues) => {
    editCompensationType({
      variables: {
        data: {
          ...values,
        },
        companyId: userData!.company.id,
        compensationTypeId: compensationTypeToEdit!.id,
      },
    });
  };

  const columns = useMemo<ExpandedColumn<CompensationType>[]>(
    () => [
      {
        Header: t('columns.compensationTypes.name')!,
        accessor: 'name',
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell({ value, isHovered }) {
          return (
            <TableActionCell isHovered={isHovered}>
              <Tooltip title={t('actions.edit')!} placement="top">
                <IconButton
                  className="editIconButton"
                  size="small"
                  onClick={() => push(link({ mode: ModalModeEnum.edit, compensationId: value }))}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <RemoveConfirmIconButton
                onClick={() => handleDelete(value)}
                confirmTitle={t('compensationTypes.deleteCompensationType')}
                confirmMessage={t('compensationTypes.deleteCompensationTypeQuestion')}
                confirmSubmitButtonTitle={t('compensationTypes.deleteCompensationType')}
              />
            </TableActionCell>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex align-items-center justify-content-between mb-16">
        <h2 className="weight-600 text-20">{t('compensationTypes.name')}</h2>

        <Button className="mr-8" onClick={() => push(link({ mode: ModalModeEnum.create }))} startIcon={<PlusIcon />}>
          {t('compensationTypes.createCompensationType')}
        </Button>
      </div>

      <Table data={compensationTypes as CompensationType[]} columns={columns} loading={loading} />

      <RightDrawer
        open={params.mode === ModalModeEnum.create}
        onClose={onCloseCreateModal}
        title={t('compensationTypes.createCompensationType')}
      >
        <NewCompensationType onSubmit={onCreate} onCancel={onCloseCreateModal} />
      </RightDrawer>

      <RightDrawer
        open={!!compensationTypeToEdit}
        onClose={onCloseEditModal}
        title={t('compensationTypes.editCompensationType')}
      >
        <NewCompensationType
          name={compensationTypeToEdit?.name}
          onSubmit={onEdit}
          onCancel={onCloseEditModal}
          submitLabel={t('actions.save')}
        />
      </RightDrawer>
    </>
  );
};

export default CompensationTypes;
