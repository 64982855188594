import { AvailabilityGroups, TeamGroupingTypesFilter } from 'views/ResourcePlanning/types';
import { Assignment, ResourcePlanningMembersQuery } from 'generated/types';
import { differenceInBusinessDays, endOfDay, isAfter, isBefore } from 'date-fns';
import { DEFAULT_CAPACITY } from 'consts';
import { addTimezoneOffset } from 'utils';

type GroupsByCapacity = {
  unassigned: MemberType[];
  partCapacity: MemberType[];
  fullCapacity: MemberType[];
  overAllocated: MemberType[];
};

type CapacityGroup = 'unassigned' | 'partCapacity' | 'fullCapacity' | 'overAllocated';
type MemberType = ResourcePlanningMembersQuery['resourcePlanningMembers'][number] & { available: boolean };
type GroupedMembers = {
  [name: string]: MemberType[];
};

const getCapacityGroup = (paginationPeriod: [Date, Date], member?: MemberType): CapacityGroup => {
  if (member && member.projects?.length === 0) {
    return member.capacity === 0 ? AvailabilityGroups.fullCapacity : AvailabilityGroups.unassigned;
  }
  if (member && member.projects?.length > 0) {
    const [start, end] = paginationPeriod;
    const periodSize = differenceInBusinessDays(end, start);

    const possibleAmountWorkingHours = periodSize * (member.capacity ?? DEFAULT_CAPACITY);

    const assignedHours = member.projects
      .reduce<Pick<Assignment, 'id' | 'startDate' | 'endDate' | 'allocationTimeAmount' | 'created_at'>[]>(
        (acc, rec) => {
          return [...acc, ...rec.assignment];
        },
        [],
      )
      .reduce((acc, rec) => {
        if (isBefore(new Date(rec.startDate), start) && isAfter(new Date(rec.endDate), end)) {
          return acc + periodSize * rec.allocationTimeAmount ?? DEFAULT_CAPACITY;
        }
        if (isAfter(new Date(rec.startDate), start) && isAfter(new Date(rec.endDate), end)) {
          return (
            acc +
              differenceInBusinessDays(addTimezoneOffset(endOfDay(end)), new Date(rec.startDate)) *
                rec.allocationTimeAmount ?? DEFAULT_CAPACITY
          );
        }
        if (isBefore(new Date(rec.startDate), start) && isBefore(new Date(rec.endDate), end)) {
          return (
            acc +
              differenceInBusinessDays(addTimezoneOffset(endOfDay(new Date(rec.endDate))), start) *
                rec.allocationTimeAmount ?? DEFAULT_CAPACITY
          );
        }
        if (isAfter(new Date(rec.startDate), start) && isBefore(new Date(rec.endDate), end)) {
          return (
            acc +
              differenceInBusinessDays(addTimezoneOffset(endOfDay(new Date(rec.endDate))), new Date(rec.startDate)) *
                rec.allocationTimeAmount ?? DEFAULT_CAPACITY
          );
        }
        return acc;
      }, 0);
    if (assignedHours === possibleAmountWorkingHours) return AvailabilityGroups.fullCapacity;

    if (assignedHours < possibleAmountWorkingHours) return AvailabilityGroups.partCapacity;

    return AvailabilityGroups.overAllocated;
  }

  return AvailabilityGroups.unassigned;
};

const getMemberGroupedByCapacity = (members: MemberType[], paginationPeriod: [Date, Date]) => {
  const rowsData = members.reduce<GroupsByCapacity>(
    (acc, rec) => {
      const memberCapacityGroup = getCapacityGroup(paginationPeriod, rec);

      if (memberCapacityGroup in acc) {
        return { ...acc, [memberCapacityGroup]: [...(acc[memberCapacityGroup] as MemberType[]), rec] };
      }

      return { ...acc, [memberCapacityGroup]: [rec] };
    },
    { unassigned: [], overAllocated: [], partCapacity: [], fullCapacity: [] },
  );

  return Object.entries(rowsData);
};

const getMemberGroupBySpecialization = (members: MemberType[], t: (value: string) => string) => {
  const groupedMembers = members.reduce<GroupedMembers>((acc, member) => {
    if (member.specialization) {
      const updatedProperty = acc[member.specialization]?.length ? [...acc[member.specialization], member] : [member];
      return {
        ...acc,
        [member.specialization]: updatedProperty,
      };
    }

    const updatedProperty = acc[t('specialization.noSpecialization')]?.length
      ? [...acc[t('specialization.noSpecialization')], member]
      : [member];
    return { ...acc, [t('specialization.noSpecialization')]: updatedProperty };
  }, {});

  return Object.entries(groupedMembers).sort(([keyA], [keyB]) => {
    if (keyB === t('specialization.noSpecialization')) {
      return -1;
    }
    if (keyA === t('specialization.noSpecialization')) {
      return 1;
    }

    return keyA.localeCompare(keyB);
  });
};

const getMemberGroupByReportingTo = (members: MemberType[]) => {
  const sortedMembers = members.sort((memberA, memberB) => {
    return `${memberA.reportingToMemberName}`.localeCompare(`${memberB.reportingToMemberName}`);
  });

  const groupedMembers = sortedMembers.reduce<GroupedMembers>((acc, member) => {
    if (!member.reportingToId) return acc;

    const updatedProperty = acc[member.reportingToId]?.length ? [...acc[member.reportingToId], member] : [member];
    return { ...acc, [member.reportingToId]: updatedProperty };
  }, {});

  return Object.entries(groupedMembers);
};

interface Props {
  groupBy: string;
  members: MemberType[];
  paginationPeriod: [Date, Date];
  t: (value: string) => string;
}

export const getTeamGroupedData = ({ groupBy, members, t, paginationPeriod }: Props) => {
  switch (groupBy) {
    case TeamGroupingTypesFilter.availability:
      return getMemberGroupedByCapacity(members, paginationPeriod);
    case TeamGroupingTypesFilter.specialization:
      return getMemberGroupBySpecialization(members, t);
    case TeamGroupingTypesFilter.reportingTo:
      return getMemberGroupByReportingTo(members);
    default:
      return [];
  }
};
