import React from 'react';
import { TeamGroupingTypesFilter } from 'views/ResourcePlanning/types';
import { ResourcePlanningMembersQuery } from 'generated/types';
import { getAcronym } from 'utils';
import styles from 'views/ResourcePlanning/components/Timeline/styles.module.scss';
import { UserInfo } from 'components';

type MemberType = ResourcePlanningMembersQuery['resourcePlanningMembers'][number] & { available: boolean };

interface Props {
  groupBy: string;
  members: MemberType[];
  key: string;
  t: (value: string) => string;
}

export const getTeamRowAccordionTitle = ({ groupBy, key, members, t }: Props) => {
  switch (groupBy) {
    case TeamGroupingTypesFilter.availability:
      return t(`resourcePlanning.availabilityGroups.${key}`);
    case TeamGroupingTypesFilter.specialization:
      return key;
    case TeamGroupingTypesFilter.reportingTo:
      const firstMember = members[0];
      return (
        <UserInfo
          titleClassName={styles.memberAccordionTitle}
          avatarTitle={getAcronym(...(firstMember?.reportingToMemberName || '').split(' '))}
          title={firstMember?.reportingToMemberName || ''}
          color={firstMember?.reportingToColor || ''}
        />
      );
    default:
      return [];
  }
};
