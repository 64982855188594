import { lazy } from 'react';
import { Route } from 'react-router-hoc';
import { idRegEx } from 'utils/regex';
import { ModalModeEnum } from 'types';

export const CompensationRoute = Route(
  {
    id: Route.params.regex(idRegEx),
    mode: Route.params.oneOf(...Object.values(ModalModeEnum)).optional,
    compensationId: Route.params.regex(idRegEx).optional,
  },
  ({ id, mode, compensationId }) => `/team-member/view/${id}/compensations/${mode}/${compensationId}`,
);

export const Compensations = CompensationRoute(
  lazy(() => import(/* webpackChunkName: "Compensations" */ './Compensations')),
);
